import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import $ from "jquery";
//import reportWebVitals from './reportWebVitals';

/*
Metodi di accesso diretto con parametri in GET
1) con uid e cid di moodle + chiave di controllo
2) anonimo con codice unico per ruolo studente/docente/tutor (DISABILITATO)
*/

let cid = $_GET('cid');
let uid = $_GET('uid');
let key = $_GET('key');
let codice = ''; // $_GET('codice');

let from = uid && key ? 'moodle' : 'direct';

//$('#root').html('<div class="loading"><div class="spinner-border text-danger" role="status"><span class="sr-only">Caricamento in corso...</span></div></div>');

$.ajax({
  url: "php/services.php",
  type: "POST",
  cache: false,
  data: {action:"getConfig", pid:window.pid, cid:cid},
  success: function(data) {
    if (data) {
      let config = JSON.parse(data);

      $('head').append('<link rel="stylesheet" type="text/css" href="'+window.tema+'">');

      if (config.menu===null) {
        $('#root').html('<h4 class="loading">ops, qualcosa è andato storto... (menu not found)</h4>')
        return
      }

      ReactDOM.render(
        <React.StrictMode>
          <App config={config} uid={uid} check={key} from={from} codice={codice} />
        </React.StrictMode>,
        document.getElementById('root')
      );

    }
  },
  error: function(xhr, status, err) {
    console.error("getConfig", status, err.toString());
  }
});

//versione con fetch
/*
fetch('php/services.php',
{
  method: 'post',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  body: new URLSearchParams({action:"getConfig", pid:window.pid, cid:cid}).toString()
}
)
.then((response) => response.json())
.then((responseJson) => {

  let config = responseJson;

  $('head').append('<link rel="stylesheet" type="text/css" href="'+window.tema+'">');

  if (config.menu===null) {

    $('#root').html('<h4 class="loading">ops, qualcosa è andato storto... (menu not found)</h4>')

  } else {

    ReactDOM.render(
      <React.StrictMode>
        <App config={config} uid={uid} check={key} from={from} codice={codice} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }

})
.catch((error) => { console.error(error); });
*/

function $_GET(variable)
{
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(0);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
