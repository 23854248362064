import $ from "jquery";
import React from 'react';
import ReactDOM from 'react-dom';
import Chart from 'chart.js/dist/chart.min.js';
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './App.scss';
import {showAlert,showMessage,localize,createMarkup,_l} from './Utilities';

var _version = "HEM Client Ver. 1.7.1";

var _cid = 0;
var _direct = false;

var _answers = [];
var _questions = [];
var _my_questions = [];

var _xDown = null;
var _yDown = null;

const _debug = window.debug;

const _survey_polling = 3*1000;         //polling sondaggi [json]
const _updates_polling = 4*1000;        //polling aggiornamenti (link, file, programma) + domande (studente) [json]

const _survey_detail_polling = 5*1000;  //polling risultati sondaggi (regia)

const _timer_polling = 5*1000;          //polling per il timer del docente
const _webinar_polling = 20*1000;       //polling apertura webinar
const _question_polling = 10*1000;      //polling domande (regia)
const _survey_delay_timer = 4*1000;     //Countdown per chiusura del sondaggio
const _survey_closing_time = 4;         //Contatore chiusura del sondaggio

const _presenza_polling = 30*1000;      //polling set presenza utente

const _mobile = window.innerWidth < 1025 ? true : false;

const _pie_colors = ['#f15728','#2987d9','#f2a81d','#d84293','#005736','#59beb3','#432a59','#a8ce67','#008c7f','#999999'];

const _loading = <div className="loading2"><div className="spinner-border text-danger" role="status"><span className="sr-only">loading...</span></div></div>

const _user =
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
  </svg>

const _check =
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check text-success" viewBox="0 0 18 18">
    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
  </svg>

const _down =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
</svg>

const _link =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
  <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
</svg>

const _close =
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 18 18">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>

const _chart =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pie-chart-fill" viewBox="0 0 16 16">
  <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
</svg>

const _zoom =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-zoom-in" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
  <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
  <path fillRule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
</svg>

const _left =
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg>

const _right =
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg>

const _arrow_r =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>

const ico_pref_on =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
</svg>

const ico_pref_off =
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
</svg>

const _eye = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 18"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>'

const _new = <sup className="new pulse">●</sup>

const _lettere = ['A','B','C','D','E','F','G','H','I','L'];

class App extends React.Component {

  constructor(props, context) {

    super(props, context);

    console.log(_version+' boot...');

    let lang = "it"; // window.navigator.userLanguage || window.navigator.language;
    lang = lang.indexOf("it")!==-1 ? 'IT' : 'EN';

    /*
    if (typeof (Cookies.get('lang')) === 'undefined') {
      let lang = window.navigator.userLanguage || window.navigator.language;
      lang = lang.indexOf("it")!==-1 ? 'IT' : 'EN';
      Cookies.set('lang', lang, { expires: 365 });
    }
    */

    this.state = {
      home:"home",
      //lang:Cookies.get('lang'),
      lang:lang,
      onAir:false,
      afterlogin:false,
      user:false,
      slides:false,
      course:false,
      contatti:false,
      bbb:false,
      upd_file:'',
      upd_link:'',
      upd_newq:'',
      upd_pubq:'',
      upd_programma:'',
      attivita_in_corso:'',
      last_update:0,
      last_time_update:'0',
      utenti:[],
    };

    _direct = this.props.from==='direct' ? true : false;

    localize(lang);
    //localize(Cookies.get('lang'));

  }

  componentDidMount() {

    _cid = this.props.config.ucid;

    // Legge i dati dell'evento e imposta i timer per il check degli aggiornamenti e del meeting
    //
    this.getDatiEvento(false);
    setInterval(() => this.checkUpdates(), _updates_polling);
    setInterval(() => this.setPresenzaUtente(), _presenza_polling);

    if (this.props.config.streaming) setTimeout(() => this.checkWebinar(), _webinar_polling);
  }

  getDatiEvento = (refresh) => {

    if (_debug) console.log('getDatiEvento('+refresh+')')

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"getDatiEvento", cid:_cid, pid:window.pid},
      success: function(data) {
        if (data) {

          if (_debug) console.log('getDatiEvento - pid='+window.pid+' - cid='+_cid)

          let course = JSON.parse(data);
          this.setState({course:course});
          this.setState({last_update:course.last_update.id});
          if (!refresh) this.checkLogin(null);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getDatiEvento error - ", status, err.toString());
      }
    });

    /* versione con fetch
    fetch('php/services.php',
    {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({action:"getDatiEvento", cid:_cid, pid:window.pid}).toString()
    }
    )
    .then((response) => response.json())
    .then((responseJson) => {

      if (_debug) console.log('getDatiEvento - pid='+window.pid+' - cid='+_cid)

      let course = responseJson;
      this.setState({course:course});
      this.setState({last_update:course.last_update.id});
      if (!refresh) this.checkLogin(null);

    })
    .catch((error) => { console.error(error); });
    */
  }

  getProgramma = (refresh) => {

    if (_debug) console.log('getProgramma')

    /*
    if (refresh && this.state.user.ruolo==='studente' && this.state.course.programma) {
      //se l'attività di programma attiva ha una domanda di valutazione, avviso l'utente
      let p = this.state.course.programma;
      for (let ii=0; ii<p.length; ii++) {
        if (p[ii].stato==='1' && p[ii].id_domanda) {
          $('#toast-message-valuta').toast('show');
          break;
        }
      }
    }
    */

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"getProgramma", pid:window.pid},
      success: function(data) {
        if (data) {
          let course = this.state.course;
          course.programma = JSON.parse(data);
          this.setState({course:course});

          //se utente loggato e senza streaming, allora leggo le slide
          if (this.state.user.login && !this.state.user.streaming) this.getSlides(course.programma);

          if (refresh && this.state.user.ruolo==='studente') {
            //se c'è almeno un'attività di programma ancora da valutare, avviso l'utente
            let p = course.programma;
            let alert_valuta = null;
            for (let ii=0; ii<p.length; ii++) {
              if (p[ii].stato==='1') break; //mi fermo all'attività in corso
              if (p[ii].id_domanda && !p[ii].valutato) {
                alert_valuta = <span className="pulse">{_l.msg_valuta}</span>
                break;
              }
            }
            this.setState({alert_valuta:alert_valuta});

          }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getProgramma error - ", status, err.toString());
      }
    });

  }

  getLinks = () => {

    if (_debug) console.log('getLinks')

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"getLinks", pid:window.pid},
      success: function(data) {
        if (data) {
          let course = this.state.course;
          course.links = JSON.parse(data);
          this.setState({course:course});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getLinks error - ", status, err.toString());
      }
    });

  }

  getFiles = () => {

    if (_debug) console.log('getFiles')

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"getFiles", pid:window.pid},
      success: function(data) {
        if (data) {
          let course = this.state.course;
          course.files = JSON.parse(data);
          this.setState({course:course});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getFiles error - ", status, err.toString());
      }
    });

  }

  getSlides = (programma) => {

    //legge le slide legate al programma attivo

    if (_debug) console.log('getSlides')

    for (let ii=0; ii<programma.length; ii++)
      if (programma[ii].stato==='1')
        $.ajax({
          url: "php/services.php",
          type: "POST",
          cache: false,
          data: {action:'getSlides', id:programma[ii].id},
          success: function(data) {
            if (data) {
              let slides = JSON.parse(data);
              if (JSON.stringify(slides)!==JSON.stringify(this.state.slides)) { //aggiorna solo se è cambiato qualcosa
                let attivita_in_corso =
                  <React.Fragment>
                    <div className="slide-attivita" dangerouslySetInnerHTML={createMarkup(programma[ii].attivita)} />
                    <div className="slide-attore">{programma[ii].attore}</div>
                  </React.Fragment>
                this.setState({slides:slides, attivita_in_corso:attivita_in_corso, home:"slides"});
              }
            } else
              this.setState({slides:false, attivita_in_corso:'', home:"home"});
          }.bind(this),
          error: function(xhr, status, err) {
            console.error("getSlides", status, err.toString());
          }
        });
  }

  getWebinar = (user) => {

    if (!this.state.course) return;

    if (_debug) console.log('getWebinar')

    /*
    let nome = user.cognome+' '+user.nome;
    if (user.ruolo==='docente') nome = '_'+nome;
    if (user.ruolo==='tutor') nome = '[ACCMED] '+nome;
    */

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"getDatiBBB", cid:this.state.course.id, uid:user.id, nome:user.nome_bbb},
      success: function(bbb) {
        if (bbb) {

          if (_debug) console.log("getDatiBBB");
          if (_debug) console.log(JSON.parse(bbb));

          this.setState({bbb:JSON.parse(bbb)});

          if (!user.login) {
            this.setState({home:"login", afterlogin:"webinar"});
            showMessage(_l.msg_session_started);
          }
          if (user.ruolo==='docente' || user.ruolo==='tutor' || (user.ruolo==='studente' && user.streaming)) {
            this.setState({home:"webinar"});
            this.setState({onAir:true});
          }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getDatiBBB error - ", status, err.toString());
      }
    });
  }

  checkWebinar = () => {

    //Controlla se c'è un webinar da mostrare (solo se utente loggato può accedere allo streaming)

    if (this.state.user.login && !this.state.user.streaming) return;

    if (_debug) console.log('checkWebinar')

    //Se streaming in corso o utente senza streaming allora no polling
    if (!this.state.onAir && this.props.config.streaming) {
      this.getWebinar(this.state.user);
      setTimeout(() => this.checkWebinar(), _webinar_polling);
    }
  }

  checkUpdates = () => {

    //Controlla se ci sono aggiornamenti dei contenuti (solo per utenti loggati)

    if (this.state.user.login===0) return;

    if (_debug) console.log('checkUpdates')

    let domande_platea = this.props.config.domande_platea;

    $.ajax({
      url: "data/check-updates.json",
      cache: false,
      success: function(data) {
        if (data && (this.state.last_time_update!==data.time)) {

          if (_debug) console.log('checkUpdates - ' + data.updates.tipo)

          this.setState({last_time_update:data.time});

          if (JSON.stringify(_answers)!==JSON.stringify(data.answers)) {
            _answers = data.answers;
            this.setState({refresh:true});
            if (_debug) console.log("REFRESH RISPOSTE ALLA PLATEA...")
          }
          if (JSON.stringify(_questions)!==JSON.stringify(data.questions)) {
            _questions = data.questions;
            this.setState({refresh:true});
            if (_debug) console.log("REFRESH DOMANDE DALLA PLATEA...")
          }

          let upd = data.updates;

          if (this.state.last_update>0 && upd.id>this.state.last_update) {

            if (upd.tipo==='ricarica' && this.state.user.ruolo!=='tutor') window.location.reload();

            this.setState({last_update:upd.id});

            if (upd.tipo==='aggiornamento') {
              if (this.state.user.ruolo==='tutor') {
                if (upd.cosa==='new_question') this.setState({upd_newq:_new});
              }
              if (this.state.user.ruolo==='docente' || this.state.user.ruolo==='moderatore' || this.state.user.ruolo==='proiettore') {
                if (upd.cosa==='pub_question') this.setState({upd_newq:_new});
                if (upd.cosa==='new_question' && (domande_platea==='2' || domande_platea==='3')) this.setState({upd_newq:_new});
              }
              if (this.state.user.ruolo==='studente') {
                if (upd.cosa==='new_question' && domande_platea==='3') this.setState({upd_pubq:_new});
                if (upd.cosa==='pub_question' && (domande_platea==='1' || domande_platea==='3')) this.setState({upd_pubq:_new});
              }
              /*
              if (upd.cosa==='link') {
                this.getLinks();
                this.setState({upd_link:_new});
              }
              if (upd.cosa==='file') {
                this.getFiles();
                this.setState({upd_file:_new});
              }
              */
              if (upd.cosa==='programma') {
                this.getProgramma(true);
                //this.setState({upd_programma:_new});
              }
              if (_debug) console.log("REFRESH "+upd.cosa)
            }
            if (upd.tipo==='nascondi' && upd.cosa==="all") {
              $("#modal-ecm").modal('hide');
              $("#modal-link").modal('hide');
              $("#modal-file").modal('hide');
              $("#modal-faculty").modal('hide');
              $("#modal-domande").modal('hide');
              $("#modal-sponsor").modal('hide');
              $("#modal-contatti").modal('hide');
              $("#modal-patrocini").modal('hide');
              $("#modal-programma").modal('hide');
            }
          }

          if (upd.cosa==="new_question") {

            if (_debug) console.log('checkUpdates - checkMyQuestions')

            $.ajax({
              url: "php/services.php",
              type: "POST",
              cache: false,
              data: {action:"checkMyQuestions", pid:window.pid},
              success: function(data) {
                if (data) {
                  let d = JSON.parse(data);
                  if (JSON.stringify(_my_questions)!==JSON.stringify(d.my_questions)) {
                    _my_questions = d.my_questions;
                    this.setState({refresh:true});
                    if (_debug) console.log("REFRESH MIE DOMANDE...")
                  }
                }
              }.bind(this),
              error: function(xhr, status, err) {
                console.error("checkMyQuestions error - ", status, err.toString());
              }
            });
          }

        }

      }.bind(this),
      error: function(xhr, status, err) {
        //console.error("check-updates error - ", status, err.toString());
      }

    });

    /*
    if (this.state.user) {
      $.ajax({
        url: "php/services.php",
        type: "POST",
        cache: false,
        data: {action:"checkMyQuestions", pid:window.pid},
        success: function(data) {
          if (data) {
            let d = JSON.parse(data);
            if (JSON.stringify(_my_questions)!==JSON.stringify(d.my_questions)) {
              _my_questions = d.my_questions;
              this.setState({refresh:true});
              if (_debug) console.log("REFRESH MIE DOMANDE...")
            }
          }
        }.bind(this),
        error: function(xhr, status, err) {
          console.error("checkMyQuestions error - ", status, err.toString());
        }
      });
    }
    */

  }

  checkUsers = () => {

    //Controlla lo stato degli utenti collegati

    if (_debug) console.log('checkUsers')

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"checkUsers", pid:window.pid},
      success: function(data) {
        if (data) {
          this.setState({utenti:JSON.parse(data)});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("checkUsers error - ", status, err.toString());
      }
    });
  }

  checkTimer = () => {

    //Controlla se c'è un timer da mostrare

    if (_debug) console.log('checkTimer')

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:"checkTimer", pid:window.pid},
      success: function(tempo) {
        tempo = parseInt(tempo);
        if (tempo>0) {
          if (_debug) console.log("Start Timer: "+tempo);
          $("#toast-timer").removeClass("timer-sforato");
          clearTimeout(this.timerDocente);
          this.setTimer(tempo,false);
        }
        if (tempo===-1) {
          if (_debug) console.log("Stop Timer");
          clearTimeout(this.timerDocente);
          showTimerDocente(0);
        }
        //riparto con il polling per gestire riavvia timer e stop timer
        setTimeout(() => this.checkTimer(), _timer_polling);
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("checkTimer error - ", status, err.toString());
      }
    });
  }

  setPresenzaUtente = () => {

    //aggiorna lo stato della presenza

    if (this.state.user) {
      if (this.state.user.ruolo==='studente') {
        $.ajax({
          url: "php/services.php",
          type: "POST",
          cache: false,
          data: {action:"setPresenzaUtente", pid:window.pid},
          success: function(data) {
          },
          error: function(xhr, status, err) {
            console.error("setPresenzaUtente error - ", status, err.toString());
          }
        });
      }
    }
  }

  setTimer = (t,sforato) => {

    //visualizza il timer

    if (_debug) console.log('setTimer')

    if (t===0) {
      //inizia il conteggio dello sforamento
      sforato = true;
      $("#toast-timer").addClass("timer-sforato");
    }

    t = sforato ? t+1 : t-1;
    let hms = sforato ? '+' + toHHMMSS(t) : toHHMMSS(t);

    showTimerDocente(hms);
    this.timerDocente = setTimeout(() => this.setTimer(t,sforato), 1*1000);

  }

  checkLogin = (course) => {

    if (_debug) console.log('checkLogin')

    if (this.props.codice) {
      //check accesso diretto con codice in url (attualmente disabilitato)
      $.ajax({
        url: "php/login.php",
        type: "POST",
        cache: false,
        data: {action:"login", codice:this.props.codice, pid:window.pid},
        success: function(user) {
          if (user) {
            this.handleLoggedIn(user);
          } else {
            showAlert("Questo codice non permette l'accesso alle funzionalità avanzate");
          }
        }.bind(this),
        error: function(xhr, status, err) {
          console.error("login error - ", status, err.toString());
        }
      });
      return;
    }

    $.ajax({
      url: "php/login.php",
      type: "POST",
      cache: false,
      //EXPERIMENT data: {action:"checklog", cid:course.id, uid:this.props.uid, key:this.props.check},
      data: {action:"checklog", cid:_cid, uid:this.props.uid, key:this.props.check},
      success: function(user) {
        if (user) {
          this.handleLoggedIn(user);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("checklog error - ", status, err.toString());
      }
    });
  }

  handleLoggedIn = (user) => {

    //gestione del post login

    if (_debug) console.log('handleLoggedIn')
    if (_debug) console.log(user);

    user = JSON.parse(user);
    this.setState({user:user, home:'home'});

    //switch corso primario -> secondario
    if (user.cid) {
      if (user.cid!==_cid) {
        _cid = user.cid;
        this.getDatiEvento(true);
      }
    }

    if (user.ruolo==='tutor' || user.ruolo==='docente' || user.ruolo==='moderatore' || user.ruolo==='proiettore') {
      setTimeout(() => this.checkTimer(), _timer_polling);
    }

    if (user.streaming) this.getWebinar(user);

    //se utente loggato e senza streaming, allora leggo le slide
    if (user.login && !user.streaming) this.getSlides(this.state.course.programma);

    //ricarico il programma (per gestire eventuali valutazioni delle attività)
    this.getProgramma(true);

  }

  doLogout = () => {

    $.ajax({
      url: "php/login.php",
      type: "POST",
      cache: false,
      data: {action:"logout"},
      success: function(data) {
        window.location.reload();
      },
      error: function(xhr, status, err) {
        console.error("logout error - ", status, err.toString());
      }
    });
  }

  onClickIT = () => {
    //Cookies.set('lang', 'IT', { expires: 365 });
    this.setState({lang: 'IT'});
    localize('IT');
  }

  onClickEN = () => {
    //Cookies.set('lang', 'EN', { expires: 365 });
    this.setState({lang: 'EN'});
    localize('EN');
  }

  resetUpdates = (what) => {
    if (what==='link') this.setState({upd_link:''});
    if (what==='file') this.setState({upd_file:''});
    if (what==='programma') this.setState({upd_programma:''});
    if (what==='new_question') this.setState({upd_newq:''});
    if (what==='pub_question') this.setState({upd_pubq:''});
  }

  gotoAreaSponsor = () => {

    if(window.confirm(_l.msg_area_sponsor)) document.getElementById("click-area-sponsor").click();
  }

  showContatti = () => {
    if (!this.state.contatti) this.setState({contatti:true});
    $('#modal-contatti').modal('show');
  }

  /*
  offWebinar = () => {
    this.setState({onAir:false, home:'off-webinar'});
  }
  */

  render() {

    if(_debug) console.log('Start rendering');

    //if (!this.state.course) return (_loading); //dati del corso non ancora disponibili, pagina muta con loading

    let adesso = Math.floor(Date.now());

    //let isLive = (adesso >= this.state.course.inizio && adesso <= this.state.course.fine) ? true : false;

    // LOADING
    //
    let home =
      <div className="row ml-1 mr-1">
        <div className="col-sm-12 col-md-6">
          <img className="santino mb-3" src={"data:image/jpg;base64,"+this.props.config.santino} alt="copertina" />
        </div>
        <div className="col-sm-12 col-md-6 scheda">
          {_loading}
        </div>
      </div>
    //
    // DEFAULT
    //
    if (this.state.course) {
      let login_or_welcome = '';
      if (this.state.user.login) {
        login_or_welcome = this.state.user.streaming ? <h5 className="welcome mb-3">{_l.lab_welcome} {this.state.user.nome}! <br /> {_l.lab_welcome_de}</h5> : <h5 className="welcome">{_l.lab_welcome} {this.state.user.nome}!<br /> {_l.lab_welcome_res1}</h5>
      }
      else {
        login_or_welcome = <button className="btn btn-primary btn-100 mb-3" onClick={()=>{this.setState({home:"login"})}}>{_l.btn_login}</button>
      }

      // EVENTO FINITO
      if (adesso > this.state.course.fine)
        login_or_welcome = this.state.user.streaming ? <h5>{_l.lab_welcome_de_end}</h5> : <h5>{_l.lab_welcome_res_end}</h5>
      //

      home =
        <div className="row ml-1 mr-1">
          <div className="col-sm-12 col-md-6">
            <img className="santino mb-3" src={"data:image/jpg;base64,"+this.props.config.santino} alt="copertina" />
            {login_or_welcome}
          </div>
          <div className="col-sm-12 col-md-6 scheda">
            {htmlSchedaHome(this.state.course)}
          </div>
        </div>

      // sfondo home per il proiettore
      if (this.state.user) {
        if (this.state.user.ruolo==='proiettore') {
           home = fileExist('sfondo.jpg') ? <div className="row"><div className="col"><img alt="" className="img-fluid slide" src="sfondo.jpg" /></div></div> : null
        }
      }
    }
    //
    // LOGIN
    //
    if (this.state.home==='login') {
      home = <Login onLoggedIn={this.handleLoggedIn} course={this.state.course} config={this.props.config} lang={this.state.lang} />
    }
    //
    // MEETING
    //
    if (this.state.home==='webinar') {
      if (this.state.bbb.joinurl)
        home = <Webinar bbb={this.state.bbb} />
    }
    //
    // SLIDES
    //
    if (this.state.home==='slides') {
        home = <GetSlides slides={this.state.slides} attivita={this.state.attivita_in_corso} />
    }
    //
    let modal_timer = '';
    let modal_utenti = '';
    let modal_quiz_studente = '';
    let modal_quiz_dettagli = '';
    let modal_domande_studente = '';
    let modal_quiz_tutor = '';
    let modal_domande_tutor = '';
    if (this.state.user) {
      if (this.state.user.ruolo==='studente') {
        modal_quiz_studente = <GetSurvey ruolo={this.state.user.ruolo} />
        modal_quiz_dettagli = <SurveyDetailStudente ruolo={this.state.user.ruolo} />
        modal_domande_studente = <DomandeStudente user={this.state.user} config={this.props.config} />
      }
      if (this.state.user.ruolo==='docente') {
        modal_quiz_studente = <GetSurvey config={this.props.config} ruolo={this.state.user.ruolo} />
        modal_quiz_dettagli = <SurveyDetailStudente ruolo={this.state.user.ruolo} />
        modal_domande_tutor = <DomandeTutor user={this.state.user} config={this.props.config} />
        modal_domande_studente = <DomandeStudente user={this.state.user} config={this.props.config} />
      }
      if (this.state.user.ruolo==='moderatore') {
        modal_quiz_studente = <GetSurvey ruolo={this.state.user.ruolo} mostra_contatti={this.props.config.mostra_contatti}  />
        modal_quiz_dettagli = <SurveyDetailStudente ruolo={this.state.user.ruolo} />
        modal_domande_tutor = <DomandeTutor user={this.state.user} config={this.props.config} />
      }
      if (this.state.user.ruolo==='proiettore') {
        modal_quiz_studente = <GetSurvey ruolo={this.state.user.ruolo} mostra_contatti={this.props.config.mostra_contatti}  />
        modal_quiz_dettagli = <SurveyDetailStudente ruolo={this.state.user.ruolo} />
        modal_domande_tutor = <DomandeTutor user={this.state.user} config={this.props.config} />
        modal_domande_studente = <DomandeStudente user={this.state.user} config={this.props.config} />
      }
      if (this.state.user.ruolo==='tutor') {
        modal_timer = <TimerTutor />
        modal_utenti = <DatiUtenti utenti={this.state.utenti} />
        modal_quiz_tutor = <SurveyTutor mostra_contatti={this.props.config.mostra_contatti} />
        modal_domande_tutor = <DomandeTutor user={this.state.user} config={this.props.config} />
        modal_domande_studente = <DomandeStudente user={this.state.user} config={this.props.config} />
      }
    }
    let modal_contatti = '';
    if (this.state.contatti) {
      modal_contatti = <Contatti course={this.state.course.titolo} user={this.state.user} config={this.props.config} />
    }

    let ruolo = this.state.user.ruolo ? this.state.user.ruolo : 'nessuno';
    let menu = this.props.config.menu[ruolo] ? this.props.config.menu[ruolo] : '';

    let navbar_regia = this.state.user.ruolo==='tutor' ? 'bg-navbar-regia' : 'bg-navbar';

    let desc_ruolo = null;
    if (ruolo==='moderatore' || ruolo==='proiettore') desc_ruolo = <div className="dropdown-v">Ruolo: {ruolo}</div>

    //let avatar = this.state.user.gravatar ? <img src={this.state.user.gravatar} width="22px" /> : _user;

    return (
      <React.Fragment>
        <div id="container">

          <nav className={"navbar navbar-expand-lg  sticky-top "+navbar_regia}>
            <div className="container">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div><img className="img-logo" src={"data:image/jpg;base64,"+this.props.config.header} alt="logo" /></div>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">

                  {menu.includes('vai_area_sp') && ruolo!=='tutor' && !this.state.bbb.joinurl ? <li className="nav-item"><button className="nav-link nav-area-sponsor" onClick={()=>{this.gotoAreaSponsor()}}>{_l.btn_area_sponsor}</button><a id="click-area-sponsor" href={"https://areasponsor.accmed.org/"+_cid} target="_blank" rel="noopener noreferrer"></a></li> : ''}
                  {menu.includes('programma') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-programma" onClick={()=>{this.resetUpdates('programma')}}>{_l.btn_programma}{this.state.alert_valuta}{this.state.upd_programma}</button></li> : ''}
                  {menu.includes('faculty') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-faculty">{_l.btn_faculty}</button></li> : ''}
                  {menu.includes('quiz') && modal_quiz_tutor ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-quiz-docente">{_l.btn_survey}</button></li> : ''}
                  {menu.includes('domande') && modal_domande_tutor ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-domande-docente" onClick={()=>{this.resetUpdates('new_question')}}>{_l.btn_domande_d}{this.state.upd_newq}</button></li> : ''}
                  {menu.includes('timer') && ruolo!=='studente' ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-timer">TIMER</button></li> : ''}
                  {menu.includes('domande') && ruolo!=='moderatore' ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-domande-studente" onClick={()=>{this.resetUpdates('pub_question')}}>{_l.btn_domanda}{this.state.upd_pubq}</button></li> : ''}
                  {menu.includes('file') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-file" onClick={()=>{this.resetUpdates('file')}} >{_l.btn_file}{this.state.upd_file}</button></li> : ''}
                  {menu.includes('link') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-link" onClick={()=>{this.resetUpdates('link')}} >{_l.btn_link}{this.state.upd_link}</button></li> : ''}
                  {menu.includes('patrocini') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-patrocini">{_l.btn_patrocini}</button></li> : ''}
                  {menu.includes('sponsor') ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-sponsor">{_l.btn_sponsor}</button></li> : ''}
                  {modal_utenti ? <li className="nav-item"><button className="nav-link" data-toggle="modal" data-target="#modal-utenti" onClick={()=>{this.checkUsers()}}>{_l.btn_utenti}</button></li> : ''}

                  {/*this.state.onAir ? <li className="nav-item"><a className="nav-link" onClick={()=>{this.offWebinar()}}>ESCI DAL MEETING</a></li> : ''*/}
                  {/*(!this.state.onAir && this.state.bbb.joinurl) ? <li className="nav-item"><a className="nav-link" onClick={()=>{window.location.reload()}}>ENTRA NEL MEETING</a></li> : ''*/}

                  {menu ?
                  <li className="nav-item dropdown">
                    <button className="nav-link dropdown-toggle" id="navbarDropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      INFO
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-3" aria-labelledby="navbarDropdown1">
                      <div className="dropdown-v"><a href="#" className="nav-l" data-toggle="modal" data-target="#modal-istruzioni">{_l.btn_istruzioni}</a></div>
                      {menu.includes('ecm') ? <div className="dropdown-v"><a href="#" className="nav-l" data-toggle="modal" data-target="#modal-ecm">{_l.btn_ecm}</a></div> : ''}
                      {menu.includes('contatti') ? <div className="dropdown-v"><a href="#" className="nav-l" onClick={()=>{this.showContatti()}}>{_l.btn_contatti}</a></div> : ''}
                    </div>
                  </li>
                  : ''}

                  <li className="nav-item dropdown">
                    <button aria-label="user" className="nav-link dropdown-toggle" id="navbarDropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {_user}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-3" aria-labelledby="navbarDropdown2">
                      <div className="dropdown-v">{this.state.user.nome ? this.state.user.nome+' '+this.state.user.cognome : <a href="#" className="nav-l" onClick={()=>{this.setState({home:"login"})}}>{_l.btn_login}</a>}</div>
                      {desc_ruolo}
                      <div className="dropdown-v"><a href="#" className="nav-l" onClick={this.onClickIT}>ITA</a> | <a href="#" className="nav-l" onClick={this.onClickEN}>ENG</a></div>
                      {(this.state.user.login && _direct) ? <div className="dropdown-v"><a href="#" className="nav-l" onClick={this.doLogout}>{_l.btn_logout}</a></div> : ''}
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </nav>

          {/*<div id="loading"></div>*/}
          {/*<div className="mt-3 ml-2 mr-2 mb-5">{home}</div> 08 04 2024*/}
          <div className="mt-3 mb-5">{home}</div>

        </div>

        <Faculty course={this.state.course} />
        <DatiEcm course={this.state.course} />
        <Sponsor course={this.state.course} />
        <Patrocini course={this.state.course} lang={this.state.lang} />
        <Link links={this.state.course.links} user={this.state.user} refresh={this.getDatiEvento} />
        <File files={this.state.course.files} user={this.state.user} refresh={this.getDatiEvento} />
        <Programma programma={this.state.course.programma} user={this.state.user} refresh={this.getProgramma} />
        <Modals config={this.props.config} />

        {modal_timer}
        {modal_contatti}
        {modal_domande_studente}
        {modal_domande_tutor}
        {modal_quiz_studente}
        {modal_quiz_dettagli}
        {modal_quiz_tutor}
        {modal_utenti}

      </React.Fragment>
    );
  }
}

/* Modal login ************************************************************** */

class Login extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      nome:'',
      cognome:'',
      email:'',
      nascita:'',
      username:'',
      password:'',
      cellulare:'',
      iscritto: true,
      recaptcha_response: false,
      codice: props.config.login.codice ? props.config.login.codice : '',
    };

  }

  onChangeCode = (e) => {
    this.setState({codice: e.target.value});
  }

  onChangeNome = (e) => {
    this.setState({nome: e.target.value});
  }

  onChangeCognome = (e) => {
    this.setState({cognome: e.target.value});
  }

  onChangeNascita = (e) => {
    this.setState({nascita: e.target.value});
  }

  onChangeEmail = (e) => {
    this.setState({email: e.target.value});
  }

  onChangeCellulare = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value.length>10) return;
    let n = parseInt(e.target.value);
    if (isNaN(n)) n = '';
    this.setState({cellulare: n});
  }

  onChangeUser = (e) => {
    this.setState({username: e.target.value});
  }

  onChangePass = (e) => {
    this.setState({password: e.target.value});
  }

  onChangeRecaptcha = (v) => {
    this.setState({recaptcha_response: v});
  }

  logIn = (e) => {

    e.preventDefault();

    if (this.state.nascita) {
      let age = getAge(this.state.nascita);
      if (age<18) {
        showAlert('Per partecipare devi essere maggiorenne.');
        return;
      }
    }

    let recaptcha_response = 'no';
    if (this.props.config.recaptcha_codice) {
      if (this.state.recaptcha_response) {
        recaptcha_response = this.state.recaptcha_response;
      } else {
        showAlert('Seleziona "Non sono un robot", per favore.');
        return;
      }
    }

    $.ajax({
      url: "php/login.php",
      type: "POST",
      cache: false,
      data: {action:"login", codice:this.state.codice, nome:this.state.nome, cognome:this.state.cognome, nascita:this.state.nascita, email:this.state.email, cellulare:this.state.cellulare, username:this.state.username, password:this.state.password, cid:this.props.course.id, pid:window.pid, recaptcha_response:recaptcha_response},
      success: function(data) {
        if (data) {
          let user = JSON.parse(data);
          if (user.iscritto)
            this.props.onLoggedIn(data);
          else
            this.setState({iscritto:false});

        } else {
          showAlert(_l.msg_logerr);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("login error - ", status, err.toString());
      }
    });

  }

  /*
  signUp = () => {
  	$('#modal-signup').modal('show')
  }
  */

  render() {

    if(_debug) console.log('Login');

    let voci_login = this.props.config.login.voci;
    let lab_dichiara2 = this.props.lang==='IT' ? this.props.config.login.lab_privacy3_it : this.props.config.login.lab_privacy3_en;

    let msg_iscrizione = '';
    if (!this.state.iscritto)
      msg_iscrizione =
        <div className="alert alert-danger mt-3">
          <h5>Non sei ancora iscritto al corso!</h5>
          <p><a href={"https://fad.accmed.org/enrol/index.php?id="+this.props.course.id} target="_blank" rel="noopener noreferrer">Clicca qui per iscriverti</a></p>
        </div>

    let html_login = '';

    if (this.props.config.mostra_login==='1') {
      html_login =
        <React.Fragment>
          <hr />
          <div className="row">
            <div className="col-12">
              <h5 className="colore-primario mb-3">{_l.lab_inuser2}</h5>
              <form className="login-form" onSubmit={this.logIn}>
                <div className="form-group">
                  <label htmlFor="InputEmail1">Username (email)</label>
                  <input type="email" className="form-control" id="InputEmail1" required onChange={this.onChangeUser} />
                </div>
                <div className="form-group">
                  <label htmlFor="InputPassword1">Password</label>
                  <input type="password" className="form-control" id="InputPassword1" required onChange={this.onChangePass}/>
                </div>
                <button id="btn-login-username" type="submit" className="btn btn-primary btn-100">{_l.btn_conf}</button>
              </form>
            </div>
          </div>
        </React.Fragment>
    }

    let html_recaptcha = '';
    if (this.props.config.recaptcha_codice)
      html_recaptcha =
        <React.Fragment>
          <div className="recaptcha">{_l.lab_umano}</div>
          <div className="recaptcha"><ReCAPTCHA sitekey={this.props.config.recaptcha_publickey} onChange={this.onChangeRecaptcha} /></div>
        </React.Fragment>

    let nascita = '';
    if (voci_login.includes('nascita')) {
      nascita =
        <>
          <label htmlFor="InputNascita">{_l.lab_nascita}</label>
          <input type="date" className="form-control" id="InputNascita" required onChange={this.onChangeNascita} />
        </>
    }
    let email = '';
    if (voci_login.includes('email')) {
      email =
        <>
          <label htmlFor="InputEmail">{_l.lab_email}</label>
          <input type="email" className="form-control" id="InputEmail" required onChange={this.onChangeEmail} />
        </>
    }
    let cellulare = '';
    if (voci_login.includes('cellulare')) {
      cellulare =
        <>
          <label htmlFor="InputCellulare">{_l.lab_cellulare}</label>
          <div className="nota">{_l.lab_cellulare_nota}</div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text cel-addon" id="cel-addon">+39</span>
            </div>
            <input type="text" className="form-control" id="InputCellulare"  aria-describedby="cel-addon" value={this.state.cellulare} required onChange={this.onChangeCellulare} />
          </div>
        </>
    }
    let privacy = '';
    if (voci_login.includes('privacy1')) {
      let lab_privacy2 = _l.lab_privacy2.replace("$ente$", this.props.config.ente);
      let url_privacy = this.props.config.s_ente==='ANM' ? 'https://www.accmed.org/privacypolicy.html' : 'https://www.forumservice.net/privacypolicy.html';
      privacy =
        <>
          <div className="nota">{lab_privacy2} <a href={url_privacy} target="_blank">privacypolicy.html</a></div>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="InputPrivacy" required />
            <label className="custom-control-label nota" htmlFor="InputPrivacy"><strong>{_l.lab_privacy3}</strong></label>
          </div>
          {voci_login.includes('privacy2')
          ?
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="InputDichiara1" required />
            <label className="custom-control-label nota" htmlFor="InputDichiara1">{_l.lab_dichiara1}</label>
          </div>
          : ''}
          {voci_login.includes('privacy3')
          ?
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="InputDichiara2" required />
            <label className="custom-control-label nota" htmlFor="InputDichiara2">{lab_dichiara2}</label>
          </div>
          : ''}
        </>
    }

    return (
      <div className="mb-5">
        <div className="row ml-1 mr-1">
          <div className="col-sm-12 col-md-6 form-login mb-3">
            <div className="row mb-4">
              <div className="col-12">
                <h5 className="colore-primario mb-3">{_l.lab_inuser1}</h5>
                <form className="login-form" onSubmit={this.logIn}>
                  <div className="form-group">
                    <label htmlFor="InputCodice">{_l.lab_codice}</label>
                    <input type="text" className="form-control" id="InputCodice" value={this.state.codice} required onChange={this.onChangeCode} />
                    <label htmlFor="InputNome">{_l.lab_nome}</label>
                    <input type="text" className="form-control" id="InputNome" required onChange={this.onChangeNome} />
                    <label htmlFor="InputCognome">{_l.lab_cognome}</label>
                    <input type="text" className="form-control" id="InputCognome" required onChange={this.onChangeCognome} />
                    {nascita}
                    {email}
                    {cellulare}
                  </div>
                  {privacy}
                  {html_recaptcha}
                  <button id="btn-login-code" type="submit" className="btn btn-primary btn-100 mt-3">{_l.btn_conf}</button>
                </form>
              </div>
            </div>
            {html_login}
            {msg_iscrizione}
          </div>
          <div className="col-sm-12 col-md-6">
            {htmlSchedaHome(this.props.course)}
          </div>
        </div>
        {
        /*
        <hr />
        <a onClick={this.signUp}>{_l.lab_registrati}</a>
        */
        }
      </div>
    );
  }
}

/* Gestione dei webinar ***************************************************** */

class Webinar extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if(_debug) console.log('Webinar');

    return (
      <div>
        <div className="embed-responsive embed-responsive-16by9 scroll-wrapper">
          <iframe title="BBBroom" id="bbb-room" frameBorder="0" src={this.props.bbb.joinurl} allowFullScreen allow="camera *; microphone *; fullscreen *; display-capture;"></iframe>
        </div>
        <div className="mb-5" />
      </div>
    );
  }
}

/* sondaggio (utente) ******************************************************* */

class GetSurvey extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      stato:0,
      risposta:false,
      json_data:false,
      countdown:false,
      votato:false,
      votando:false,
      timer:0,
      timeout:false,
      timerOn:0,
      start_survey:0,
      last_time_update:0,
      contatore:_survey_closing_time,
    };

  }

  componentDidMount() {
    this.getData();
    //this.interval = setInterval(() => this.getData(), _survey_polling);
    this.interval = setInterval(() => this.checkUpdates(), _survey_polling);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onClicRisposta = (r) => {

    if (this.state.votando || this.state.votato) return;

    let tempo =  Date.now() - this.state.start_survey;

    this.setState({risposta: r});
    this.setState({votando:true});

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setSurvey', id_risposta:r, id_domanda:this.state.json_data[0].id, tempo:tempo},
      success: function(data) {
        if (data) {
          if (this.counter) clearInterval(this.counter);
          this.setState({stato:0, contatore:_survey_closing_time, votato:true, votando:false, start_survey:0});
          this.getData();
        }
        else
          showMessage(_l.msg_syserr);
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setSurvey error - ", status, err.toString());
      }
    });
  }

  closeSurvey = () => {
    if (this.state.votato) {
      $('#modal-survey-studente').modal('hide');
      return;
    }
  }

  LeaveSurvey = () => {

    if (this.state.votato) {
      $('#modal-survey-studente').modal('hide');
      return;
    }

    let c = window.confirm(_l.msg_leave_survey);
    if (c) {
      $.ajax({
        url: "php/services.php",
        type: "POST",
        cache: false,
        data: {action:'setSurvey', id_risposta:0, id_domanda:this.state.json_data[0].id, tempo:0},
        success: function(data) {
          $('#modal-survey-studente').modal('hide');
        },
        error: function(xhr, status, err) {
          console.error("setSurvey error - ", status, err.toString());
        }
      });
    } else {
      //nothing
    }
  }

  checkUpdates = () => {

    if(_debug) console.log('checkUpdates - Survey');

    $.ajax({
      url: "data/check-survey.json",
      cache: false,
      success: function(data) {
        if (data && (this.state.last_time_update!==data.time)) {
          this.getData();
          this.setState({last_time_update:data.time});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        //console.error("check-survey error - ", status, err.toString());
      }
    });
  }

  getData = () => {

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSurvey', pid:window.pid},
      success: function(data) {

        if (data==='votato') {
          this.setState({votato:true, votando:false});
          return;
        }

        if (data) {

          //json_data[0].stato = 1 -> sondaggio in corso
          //json_data[0].stato = 3 -> mostra risultati

          var json_data = JSON.parse(data);

          //Sondaggio in corso
          if (json_data[0].stato==1 && (JSON.stringify(json_data)!==JSON.stringify(this.state.json_data))) { //14 12 2021

            //this.setState({json_data:json_data, stato:1, start_survey:Date.now()});
            this.setState({json_data:json_data, stato:1, votato:false, start_survey:Date.now()}); //14 12 2021

            //Timer specifico per il quiz, non usato
            /*
            if (!this.timer && json_data[0].timer!=0){
              this.setState({timer: json_data[0].timer});
              this.timer = setInterval(() => this.checkTimer(), 1000);
            }
            */

            $('#modal-survey-studente').modal('show');
            $('#modal-survey-detail-studente').modal('hide'); //14 12 2021
          }

          //Mostra risultati
          //mostra solo quando l'eventuale conto alla rovescia è terminato
          if (json_data[0].stato==3 && this.state.stato!==3 && this.state.contatore===_survey_closing_time) { //14 12 2021

            this.setState({stato:3});

            ReactDOM.unmountComponentAtNode(document.getElementById('u-survey-detail')); //14 12 2021

            ReactDOM.render(<GetSurveyDetail mostra_contatti={this.props.mostra_contatti} ruolo={this.props.ruolo} data={json_data} />, document.getElementById('u-survey-detail'));

            $('#modal-survey-studente').modal('hide');
            $('#modal-survey-detail-studente').modal('show');
          }

        } else {

          //Nessun dato dalla query (survey non aperta o chiusa dal tutor)

          if (this.state.stato===1 && this.state.contatore===_survey_closing_time) {
            this.counter = setInterval(() => this.checkCountdown(), 1000);
            clearInterval(this.timer);
            this.timer = false;
            this.setState({timerOn:0, timeout:false});
          }

          if (this.state.stato===0 || this.state.stato===3) {
            $('#toast-survey-detail').toast('hide');
            $('#modal-survey-studente').modal('hide');
            $('#modal-survey-detail-studente').modal('hide');
            document.getElementById('zoom').innerHTML = '';

            if (this.timer) {
              clearInterval(this.timer);
              this.timer = false;
            }
            if (this.counter) {
              clearInterval(this.counter);
              this.counter = false;
            }
            this.setState({json_data:false, stato:0, start_survey:0, contatore:_survey_closing_time, votato:false, timer:0, timerOn:0, timeout:false, risposta:false});

            if (document.getElementById('u-survey-detail'))
              ReactDOM.unmountComponentAtNode(document.getElementById('u-survey-detail'));

          }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getSurvey error - ", status, err.toString());
      }
    });
  }

  checkCountdown = () => {

    if(_debug) console.log('checkCountdown');

    let contatore = this.state.contatore-1;
    if (contatore===0) {
      clearInterval(this.counter);
      this.counter = false;
      //this.setState({stato: 0, json_data: false, contatore: _survey_closing_time}); //21 12 2021 - aggiunto init json_data
      this.setState({stato: 0, contatore: _survey_closing_time});
      $('#modal-survey-studente').modal('hide');
    } else {
      this.setState({contatore: contatore});
    }
  }

  /* Timer specifico per il quiz, non usato
  checkTimer = () => {

    if(_debug) console.log('checkTimer');

    let timer = this.state.timer-1;
    if (timer===0) {
      clearInterval(this.timer);
      this.timer = false;
      this.setState({timer:0, timeout:true, timerOn:toHHMMSS(0)});
    } else {
      this.setState({timer:timer, timerOn:toHHMMSS(timer)});
    }
  }
  */

  render() {

    if(_debug) console.log('GetSurvey');

    let fontsize_domanda = (this.props.ruolo==='studente' || this.props.ruolo==='tutor') ? 'testo-domanda' : 'testo-domanda-lg'
    let fontsize_risposta = (this.props.ruolo==='studente' || this.props.ruolo==='tutor') ? 'testo-risposta' : 'testo-risposta-lg'

    if (this.state.json_data) {

      let domanda = <div className={"mb-3 "+fontsize_domanda} dangerouslySetInnerHTML={createMarkup(this.state.json_data[0].testo)} />
      let immagine_d = this.state.json_data[0].immagine_d;
      if (immagine_d) {
        domanda =
          <div className="row mb-3">
            <div className="col-sm-12 col-md-9">{domanda}</div>
            <div className="col-sm-12 col-md-3">
              <img width="100%" className="img-fluid zoom-in" src={"data:image/jpg;base64,"+immagine_d} onClick={()=>zoomIn(immagine_d)} alt="" />
              {_mobile ? '' : <div className="zoom-icon">{_zoom}</div>}
            </div>
          </div>
      }

      let domanda_rapida = this.state.json_data[0].rapide;

      let risposte = [];
      for (let ii=0; ii<this.state.json_data.length; ii++) {

        if (this.state.votato && this.state.risposta!==this.state.json_data[ii].id_risposta) continue; //mostra solo la risposta scelta

        let risposta = '';
        let risposta_on = ''; // (this.state.risposta===this.state.json_data[ii].id_risposta) ? ' sel-risposte' : ''; //scelta evidenziata
        if (domanda_rapida==='0') {
          //let testo_risposta = _lettere[ii] + ' ' + this.state.json_data[ii].risposta;
          //let html_risposta = <div className={"num-risposta num-risposta-"+_lettere[ii]} dangerouslySetInnerHTML={createMarkup(testo_risposta)} />
          let html_risposta =
            <table>
              <tbody>
                <tr>
                  <td className={"td-risposta-"+_lettere[ii]}>{_lettere[ii]}</td>
                  <td className="txt-risposta"><div className={fontsize_risposta} dangerouslySetInnerHTML={createMarkup(this.state.json_data[ii].risposta)} /></td>
                </tr>
              </tbody>
            </table>
          risposta = <button type="button" className={"btn btn-risposte btn-100 mb-1 div-risposta-"+_lettere[ii]+risposta_on} onClick={()=>this.onClicRisposta(this.state.json_data[ii].id_risposta)}>{html_risposta}</button>
        } else {
          let html_risposta = <div dangerouslySetInnerHTML={createMarkup(this.state.json_data[ii].risposta)} />
          risposta = <button type="button" className={"btn btn-100 mb-1 btn-risposta btn-risposta-"+_lettere[ii]+risposta_on} onClick={()=>this.onClicRisposta(this.state.json_data[ii].id_risposta)}>{html_risposta}</button>
        }

        let immagine_r = this.state.json_data[ii].immagine_r;
        let div_risposta = '';
        if (immagine_r) {
          div_risposta =
            <div className="row mb-4" key={'r-'+ii}>
              <div className="col-sm-12 col-md-9">{risposta}</div>
              <div className="col-sm-12 col-md-3">
                <img alt="" className={"img-fluid zoom-in div-risposta-"+_lettere[ii]} src={"data:image/jpg;base64,"+immagine_r} onClick={()=>zoomIn(immagine_r)} />
                {_mobile ? '' : <div className="zoom-icon">{_zoom}</div>}
              </div>
            </div>
        } else {
          div_risposta =
            <div className="row mb-4" key={'r-'+ii}>
              <div className="col-sm-12 col-md-12">{risposta}</div>
            </div>
        }

        risposte[ii] =
          <React.Fragment key={'rr-'+ii}>
            {div_risposta}
          </React.Fragment>

      }

      let countdown = false;
      if (this.state.timerOn && !this.state.timeout) {
        countdown = this.state.timerOn;
      }
      if (this.state.contatore!==_survey_closing_time) {
        countdown = _l.msg_counter1+this.state.contatore+_l.msg_counter2;
      }

      let btn_invio = countdown ? <div className="alert alert-danger" role="alert">{countdown}</div> : <h6 className="colore-primario font-weight-bold">{_l.lab_risposte}</h6>;

      if (this.state.votando) btn_invio = _loading;

      if (this.state.votato) btn_invio = <button className="btn btn-primary btn-100 mr-2" onClick={this.closeSurvey}>{_l.btn_sent}</button>

      let div_domanda = '';
      let modal_width = 'modal-xs';
      if (domanda_rapida==='0') {
        modal_width = 'modal-lg';
        if (this.props.ruolo==='docente') modal_width = 'modal-xl';
        if (this.props.ruolo==='proiettore' || this.props.ruolo==='moderatore') modal_width = 'modal-full';
        div_domanda =
          <React.Fragment>
            {domanda}
            <hr />
          </React.Fragment>
      }

      return (
        <div className="modal fade" id="modal-survey-studente" role="dialog" data-backdrop="static" aria-labelledby="modal-survey-studente-title" aria-hidden="true">
          <div className={"modal-dialog modal-dialog-scrollable "+modal_width} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modal-link-title">{_l.btn_survey}</h5>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={this.LeaveSurvey}>{_close}</span>
                </button>
              </div>
              <div className="modal-body mt-1">
                {div_domanda}
                <div className="mb-3">{btn_invio}</div>
                {risposte}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (null);
    }
  }
}

/* lista sondaggi (tutor) *************************************************** */

class GetSurveys extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      json_data:false,
    };
  }

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = () => {
    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSurveys', pid:window.pid},
      success: function(data) {
        if (data) {
          this.setState({json_data: JSON.parse(data)});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getSurveys error - ", status, err.toString());
      }
    });

  }

  getDetail = (id) => {

    /* se non mi servono i dettagli del survey in GetSurveyDetail posso usare quanto segue, però devo avere 'ii' e non 'id'
    let data = [];
    data[0] = this.state.json_data[ii];
    ReactDOM.render(<GetSurveyDetail ruolo='docente' data={data} />, document.getElementById('dettaglio-'+id));
    */

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSurvey', pid:window.pid, sid:id},
      success: function(data) {
        if (data) {
          ReactDOM.render(<GetSurveyDetail mostra_contatti={this.props.mostra_contatti} ruolo='tutor' data={JSON.parse(data)} refresh={this.getSurveys} />, document.getElementById('dettaglio-'+id));
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getDetail error - ", status, err.toString());
      }
    });

  }

  render() {

    if(_debug) console.log('GetSurveys');

    if (this.state.json_data) {

      //ciclo per ogni gruppo, ad ogni cambio di nome_gruppo creo un tab e riciclo per creare il contenuto di quel tab
      //
      let label = '';
      let html_tabs = [];
      let html_content = [];
      let d = this.state.json_data;

      let xx=0;
      for (let jj=0; jj<d.length; jj++) {

        if (d[jj].nome_gruppo!==label) {

          xx++;

          let active = label==='' ? 'show active' : '';
          label = d[jj].nome_gruppo;
          /*
          html_tabs[jj] =
            <a key={jj} className={"nav-link nav-programma mr-1 "+active} data-toggle="tab" href={"#nav-s"+jj} role="tab">{xx}) {label}</a>
          */

          let kk=1;
          let qpill = [];
          let qcard = [];
          let tab_in_esecuzione = false;

          for (let ii=jj; ii<d.length; ii++) {

            if (d[ii].nome_gruppo!==label) break;

            let id = d[ii].id;

            let data = '';
            if (d[ii].stato==='1' || d[ii].stato==='3') {
              tab_in_esecuzione = true;
              data = <span className="evidenzia-verde">{'(in esecuzione, '+d[ii].data+')'}</span>;
            }
            if (d[ii].stato==='2') data = <span className="evidenzia-rosso">{'(eseguito, '+d[ii].data+')'}</span>;

            qpill[ii] = <a key={ii} onClick={()=>this.getDetail(id)} className="nav-link" id={"v-pills-x-tab-"+id} data-toggle="pill" href={"#v-pills-x-"+id} role="tab" aria-controls={"v-pills-x-"+id}>{xx}.{kk++}) {d[ii].titolo}<br />{data}</a>
            qcard[ii] = <div key={ii} className="tab-pane fade" id={"v-pills-x-"+id} role="tabpanel" aria-labelledby={"v-pills-x-tab-"+id}><div id={'dettaglio-'+id}>{_loading}</div></div>

          }

          let qdiv =
            <div className="row" key={jj}>
              <div className="col-sm-12 col-md-4">
                <div className="nav flex-column nav-pills" id={"v-pills-tab-"+jj} role="tablist" aria-orientation="vertical">{qpill}</div>
              </div>
              <div className="col-sm-12 col-md-8">
                <div className="tab-content" id={"v-pills-tabContent-"+jj}>{qcard}</div>
              </div>
            </div>

          let html_label = tab_in_esecuzione ? <span className="evidenzia-verde">{label}</span> : label;
          html_tabs[jj] = <a key={jj} className={"nav-link nav-programma mr-1 "+active} data-toggle="tab" href={"#nav-s"+jj} role="tab">{xx}) {html_label}</a>

          html_content[jj] = <div key={jj} className={"tab-pane fade "+active} id={"nav-s"+jj} role="tabpanel">{qdiv}</div>

        }
      }

      return (
        <React.Fragment>
          <div className="nav nav-pills nav-fill mb-3" role="tablist">{html_tabs}</div>
          <hr />
          <div className="tab-content" role="tabpanel">{html_content}</div>
        </React.Fragment>
      );

    } else {
      return false;
    }
  }
}

/* dettagli sondaggio (studente/tutor) ************************************** */

class GetSurveyDetail extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      json_data:false,
      risposte:'',
      chart:false,
      chart_type:this.props.data[0].chart,
      stato:this.props.data[0].stato,
      timer:0, //this.props.data[0].timer, //valore di countdown del tempo di apertura preimpostato
      timerOn:0,
    };

  }

  componentDidMount() {
    this.getData();
    if (this.state.stato==1) this.interval = setInterval(() => this.getData(), _survey_detail_polling);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkTimer = () => {

    //mostra il tempo di apertura
    let timer = this.state.timer+1;
    this.setState({timer:timer, timerOn:toHHMMSS(timer)});
    /*
    //countdown del tempo di apertura
    let timer = this.state.timer-1;
    if (timer===0) {
      clearInterval(this.timer);
      this.setState({timer:0, timerOn:toHHMMSS(0)});
    } else {
      this.setState({timer:timer, timerOn:toHHMMSS(timer)});
    }
    */
  }

  changeChartType = (t) => {
    this.doChart(t);
    this.setState({chart_type: t});
  }

  getData = () => {

  	//TODO
    /*
    if (this.state.stato==3 && this.props.ruolo==='studente')
      //leggo i risultati del sondaggio da file json
    else
      //leggo i risultati del sondaggio da DB
    */
    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSurveyDetail', id:this.props.data[0].id},
      success: function(data) {
        if (data) {
          this.setState({json_data: JSON.parse(data)});
          this.doChart(this.props.data[0].chart);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("GetSurveyDetail error - ", status, err.toString());
      }
    });
  }

  pubSurvey = (e) => {

    var stato = 0;
    if (this.state.stato==0) stato = 1; //mai aperto -> aperto
    if (this.state.stato==1) stato = 4; //aperto -> in chiusura
    if (this.state.stato==2) stato = 3; //chiuso -> mostra risultati
    if (this.state.stato==3) stato = 2; //mostra risultati -> chiuso
    if (this.state.stato==4) stato = 2; //in chiusura -> chiuso

    //if (stato===1 && this.state.timer!=0) this.timer = setInterval(() => this.checkTimer(), 1000);
    if (stato===1) this.timer = setInterval(() => this.checkTimer(), 1000);
    if (stato===4) {
      clearInterval(this.timer);
      this.setState({timer:0, timerOn:''});
    }

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'pubSurvey', pid:window.pid, id:this.props.data[0].id, stato:stato, chart:this.state.chart_type},
      success: function(data) {
        if (data==='notnow') {
          showMessage(_l.msg_nosurvey);
        } else {
          this.setState({stato: stato});
          if (stato===1) {
            this.interval = setInterval(() => this.getData(), _survey_detail_polling);
          }
          if (stato===2) {
            clearInterval(this.interval);
          }
          if (stato===4) {
            setTimeout(function(){ this.pubSurvey() }.bind(this), _survey_delay_timer); //Chiusura del sondaggio tra _survey_delay_timer secondi...
          }
          this.props.refresh(); //refresh della lista sondaggi (GetSurveys) per evidenziare lo stato del sondaggio
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("pubSurvey error - ", status, err.toString());
      }
    });
  }

  reopenSurvey = (voti) => {

    //riapre il sondaggio (stato=0)  mantenendo/cancellando i voti ricevuti

    if (!voti) {
      let c = window.confirm("Stai per riaprire il sondaggio CANCELLANDO i voti ricevuti! Confermi?");
      if (!c) return;
    }

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'pubSurvey', pid:window.pid, id:this.props.data[0].id, stato:0, voti:voti, chart:this.state.chart_type},
      success: function(data) {
        this.props.refresh();
        this.setState({stato: 0});
        if (!voti) this.getData();
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("pubSurvey error - ", status, err.toString());
      }
    });
  }

  mostraDatiVotanti = (id,dati) => {

    let html = '';
    html += '<p>Questi sono i contatti di coloro che hanno votato per la risposta "'+_lettere[id]+'".<br />Facendo tap su uno dei contatti si procederà a contattarli via Whatsapp (nel caso lo usino!).</p>';
    let cellulari = false;
    for (let ii=0; ii<dati.length; ii++) {
      if (dati[ii].cellulare) {
        if (parseInt(dati[ii].cellulare) && dati[ii].cellulare.length>=9) {
          cellulari = true;
          let url = 'https://wa.me/+39' + dati[ii].cellulare;
          html += '<p>';
          html += '<a class="mr-2" data-toggle="collapse" aria-expanded="false" aria-controls="collapse-cel-'+ii+'" href="#collapse-cel-'+ii+'">'+_eye+'</a>';
          html += '<a class="mr-2" target="_blank" href="'+url+'">Utente '+ (ii+1) +'</a>';
          html += '<a target="_blank" class="collapse" id="collapse-cel-'+ii+'" href="'+url+'">' + dati[ii].cellulare + '</a>';
          html += '</p>';
        }
      }
    }
    if (!cellulari) html = '<p>Tutti gli utenti che hanno votato questa risposta non hanno fornito il numero di cellulare.</p>';
    html += '<p />';
    $('#u-survey-detail-votanti').html(html);
    $('#modal-survey-detail-votanti').modal('show');
  }

  doChart = (chart_type) => {

    let labels = [];
    let values = [];
    let bcolor = [];

    let totale = 0;
    for (let ii=0; ii<this.state.json_data.length; ii++) totale = totale + parseInt(this.state.json_data[ii].voti,10);

    let domanda_rapida = this.props.data[0].rapide;

    let fontsize_progress = (this.props.ruolo==='studente' || this.props.ruolo==='tutor') ? 'testo-progress' : 'testo-progress-lg';

    let risposte = []
    for (let ii=0; ii<this.props.data.length; ii++) {

      let voti = 0;
      let percentuale = 0;
      let dati_votanti = null;
      for (let jj=0; jj<this.state.json_data.length; jj++) {
        if (this.state.json_data[jj].id_risposta===this.props.data[ii].id_risposta) {
          voti = this.state.json_data[jj].voti;
          dati_votanti = this.state.json_data[jj].dati_votanti;
          percentuale = Math.round(100/totale*parseInt(voti,10));
          if (isNaN(percentuale)) percentuale = 0;
          break;
        }
      }

      if (domanda_rapida==='0')
        //labels[ii] = _l.btn_risposta+_lettere[ii]+': '+percentuale+'%';
        labels[ii] = _lettere[ii]+': '+percentuale+'%';
      else
        labels[ii] = this.props.data[ii].risposta+': '+percentuale+'%';

      values[ii] = parseInt(voti,10);
      bcolor[ii] = _pie_colors[ii];

      let div_votanti = null;
      if ((this.props.ruolo==='moderatore' || this.props.ruolo==='tutor') && this.props.mostra_contatti==='1' && dati_votanti!==null)
        div_votanti = <button type="button" className="btn btn-dark btn-sm btn-100 mb-1" onClick={()=>this.mostraDatiVotanti(ii,dati_votanti)}>Contatti votanti risposta "{_lettere[ii]}"</button>

      let div_percentuale =
        <div className="progress mb-1" key={'p-'+ii}>
          <div className={"p-2 mt-2 "+fontsize_progress} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>

      if (percentuale)
        div_percentuale =
          <div className="progress mb-1" key={'p-'+ii}>
            <div className={"progress-bar p-2 "+fontsize_progress} style={{color:'white', backgroundColor:_pie_colors[ii], width: percentuale+"%"}} aria-valuenow={{width: percentuale+"%"}} aria-valuemin="0" aria-valuemax="100">{percentuale+'%'}</div>
          </div>

      let corretta = this.props.data[ii].corretta==='1' ? _check : '';

      let risposta = '';
      let risposta_on = (this.state.risposta===this.props.data[ii].id_risposta) ? ' sel-risposte' : '';

      let fontsize_risposta = (this.props.ruolo==='studente' || this.props.ruolo==='tutor') ? 'testo-risposta' : 'testo-risposta-lg'

      if (domanda_rapida==='0') {
        let html_risposta =
          <table>
            <tbody>
              <tr>
                <td className={"td-risposta-"+_lettere[ii]}>{_lettere[ii]}</td>
                <td className="txt-risposta"><div className={fontsize_risposta} dangerouslySetInnerHTML={createMarkup(this.props.data[ii].risposta)} /></td>
              </tr>
            </tbody>
          </table>
        risposta = <button type="button" className={"btn btn-risposte btn-100 mb-1 div-risposta-"+_lettere[ii]+risposta_on}>{html_risposta} {corretta}</button>
      } else {
        let html_risposta = <div dangerouslySetInnerHTML={createMarkup(this.props.data[ii].risposta)} />
        risposta = <button type="button" className={"btn btn-100 mb-1 btn-risposta btn-risposta-"+_lettere[ii]+risposta_on}>{html_risposta} {corretta}</button>
      }

      let immagine_r = this.props.data[ii].immagine_r;
      let div_risposta = '';
      if (immagine_r) {
        div_risposta =
          <div className="row" key={'r-'+ii}>
            <div className="col-sm-12 col-md-9">{risposta}</div>
            <div className="col-sm-12 col-md-3">
              <img alt="" className={"img-fluid mb-1 zoom-in div-risposta-"+_lettere[ii]} src={"data:image/jpg;base64,"+immagine_r} onClick={()=>zoomIn(immagine_r)} />
              {_mobile ? '' : <div className="zoom-icon">{_zoom}</div>}
            </div>
          </div>
      } else {
        div_risposta =
          <div className="row" key={'r-'+ii}>
            <div className="col-sm-12 col-md-12">{risposta}</div>
          </div>
      }

      risposte[ii] =
        <div className="mb-4" key={'dr-'+ii}>
          {div_risposta}
          {div_percentuale}
          {div_votanti}
        </div>

    }

    let data = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: bcolor,
          borderColor: bcolor,
        },
      ]
    }

    let opzioni = {
      aspectRatio: 2,
      layout: {
        padding: 0,
      },
      plugins: {
        legend:
          {
            display: true,
            position: 'right',
            labels: {
              padding: 20,
              usePointStyle: true
            }
          }
        }
    }
    if (this.state.chart) this.state.chart.destroy();
    let chart = new Chart(document.getElementById("Sondaggio-"+this.props.data[0].id), {
                //type: chart_type,
                type: 'doughnut',
                data: data,
                options: opzioni
    });

    this.setState({chart:chart});
    this.setState({risposte:risposte});

  }

  render() {

    if(_debug) console.log('GetSurveyDetail');

    if (!this.state.json_data)
      return (<React.Fragment>{_loading}</React.Fragment>);

    let fontsize_domanda = (this.props.ruolo==='studente' || this.props.ruolo==='tutor') ? 'testo-domanda' : 'testo-domanda-lg';

    let domanda = <div className={fontsize_domanda} dangerouslySetInnerHTML={createMarkup(this.props.data[0].testo)} />
    let immagine_d = this.props.data[0].immagine_d;
    if (immagine_d) {
      domanda =
        <div className="row mb-2">
          <div className="col col-md-9">{domanda}</div>
          <div className="col col-md-3">
            <img alt="" className="img-fluid zoom-in" src={"data:image/jpg;base64,"+immagine_d} onClick={()=>zoomIn(immagine_d)} />
            {_mobile ? '' : <div className="zoom-icon">{_zoom}</div>}
          </div>
        </div>
    }

    let comandi = '';
    let div_voti = '';
    let div_vincitori = '';
    let votanti = this.state.json_data[0].votanti;
    let vincitori = this.state.json_data[0].vincitori;
    let domanda_rapida = this.props.data[0].rapide;

    if (this.props.ruolo==='tutor') {

      let tot = 0;
      let percento = 0;
      for (let ii=0; ii<this.state.json_data.length; ii++) tot += parseInt(this.state.json_data[ii].voti);
      if (isNaN(tot)) tot = 0;

      if (tot>votanti) votanti = tot; /* se ci sono + voti che votanti pareggio i conti... */
      percento = tot===0 ? 0 : Math.round((tot/parseInt(votanti))*100);
      div_voti =
        <div className="row mb-3">
          <div className="col col-4">{_l.lab_votanti} <strong>{votanti}</strong></div>
          <div className="col col-4">{_l.lab_voti} <strong>{tot}</strong></div>
          <div className="col col-4">
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{width: percento+"%"}} aria-valuenow={{width: percento+"%"}} aria-valuemin="0" aria-valuemax="100">{percento}%</div>
            </div>
          </div>
        </div>

      let lista_vincitori = [];
      for (let ii=0; ii<vincitori.length; ii++) {
        let tempo = vincitori[ii].tempo / 1000; // Math.floor(vincitori[ii].tempo / 1000);
        lista_vincitori[ii] =
          <div key={ii}>
            {(ii+1) + ') ' + vincitori[ii].nome + ' ' + vincitori[ii].cognome + ' ('+tempo+' sec.)' }
          </div>
      }
      if (vincitori.length)
        div_vincitori =
          <React.Fragment>
            <h5>Hanno risposto correttamente:</h5>
            {lista_vincitori}
          </React.Fragment>

      let theTimer = '';
      if (this.state.timerOn) theTimer = ' ('+this.state.timerOn+')';

      let apri_chiudi = '';
      if (this.state.stato==0) apri_chiudi = <button onClick={this.pubSurvey} className="btn btn-success btn-sm btn-100">{_l.lab_start_survey}</button>
      if (this.state.stato==1) apri_chiudi = <button onClick={this.pubSurvey} className="btn btn-danger btn-sm btn-100">{_l.lab_stop_survey + theTimer}</button>
      if (this.state.stato==3) apri_chiudi = <button onClick={this.pubSurvey} className="btn btn-danger btn-sm btn-100">{_l.lab_hide_survey}</button>
      if (this.state.stato==4) apri_chiudi = <button disabled className="btn btn-danger btn-sm btn-100">{_l.msg_stopsurvey}</button>

      if (this.state.stato==2)
        apri_chiudi =
          <React.Fragment>
            <button onClick={()=>{this.reopenSurvey(0)}} className="btn btn-danger btn-sm btn-100 mb-1">riapri il sondaggio cancellando i voti ricevuti</button>
            <button onClick={()=>{this.reopenSurvey(1)}} className="btn btn-warning btn-sm btn-100 mb-1">riapri il sondaggio mantenendo i voti ricevuti</button>
            <button onClick={this.pubSurvey} className="btn btn-success btn-sm btn-100">{_l.lab_show_survey}</button>
          </React.Fragment>

      /*
      comandi =
        <div className="row">
          <div className="col">{apri_chiudi}</div>
          <div className="col"><button onClick={()=>this.changeChartType('pie')} className="btn btn-primary btn-sm btn-100">ciambella</button></div>
          <div className="col"><button onClick={()=>this.changeChartType('bar')} className="btn btn-primary btn-sm btn-100">istogramma</button></div>
        </div>
      */
      comandi = <div className="mb-3">{apri_chiudi}</div>
    }

      /*
      let div_chart =
        <React.Fragment>
          <div className="row">
            <div className="col-6"><h6 className="colore-primario font-weight-bold">{_l.lab_res_survey}</h6></div>
            <div className="col-6 text-right"><button className="btn btn-primary bt-100" type="button" data-toggle="collapse" data-target="#collapse-ac" aria-expanded="false" aria-controls="collapse-ac">{_l.btn_pie} {_chart}</button></div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-10">
              <div className="collapse" id="collapse-ac">
                <div className="card card-body p-0">
                  <canvas id={"Sondaggio-"+this.props.data[0].id}></canvas>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      */

      let div_chart =
        <React.Fragment>
          <div className="row">
            <div className="col-12 text-right"><button className="btn btn-primary bt-100" type="button" data-toggle="collapse" data-target="#collapse-ac" aria-expanded="false" aria-controls="collapse-ac">{_l.btn_pie} {_chart}</button></div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-10">
              <div className="collapse" id="collapse-ac">
                <div className="card card-body p-0">
                  <canvas id={"Sondaggio-"+this.props.data[0].id}></canvas>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>


    let div_domanda = '';
    if (domanda_rapida==='0') {
      div_domanda =
        <React.Fragment>
          <div className="mb-3">{domanda}</div>
          {/*<hr />*/}
        </React.Fragment>
    }

    return (
      <React.Fragment>
        {comandi}
        {div_voti}
        {div_domanda}
        {/*div_chart*/}
        <div className="row mb-2">
          <div className="col-12">{this.state.risposte}</div>
        </div>
        {div_chart}
        {div_vincitori}
      </React.Fragment>
    );
  }
}

/* form domanda platea ****************************************************** */

class FormQuestion extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      nome:'',
      domanda:'',
      speaker:0,
    };

  }

  onChangeNome = (e) => {
    this.setState({nome: e.target.value});
  }
  onChangeDomanda = (e) => {
    if (e.target.value.length<=400) this.setState({domanda: e.target.value});
  }
  handleSpeaker = (v) => {
    this.state.speaker = v;
    this.setState({speaker: v});
  }

  sendQuestion = (e) => {

    e.preventDefault();

    if (this.state.domanda.trim()==='') return;

    let stato = this.props.domande_platea==='3' ? 1 : 0; //3 = DOMANDE VISIBILI A TUTTI SENZA FILTRI REGIA (quindi pubblico automaticamente => stato=1)

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'newQuestion', pid:window.pid, nome:this.state.nome, speaker:this.state.speaker, domanda:this.state.domanda, stato:stato},
      success: function(data) {
        if (data) {
          showMessage(_l.msg_quesok);
          this.setState({domanda: ''});
        } else {
          showMessage(_l.msg_syserr);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("newQuestion error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('FormQuestion');

    return (
      <React.Fragment>
      <div className="mb-3">
        <form onSubmit={this.sendQuestion}>
          <div className="mb-1"><textarea className="form-control" onChange={this.onChangeDomanda} value={this.state.domanda} placeholder={_l.pla_domanda} rows="5" maxLength="400" required /></div>
          <div className="nota mb-3">{_l.pla_car1}{400-this.state.domanda.length}{_l.pla_car2}</div>
          <div className="row">
            <div className="col-sm-12 col-md-3">{_l.lab_destinatario}</div>
            <div className="col-sm-12 col-md-9"><GetSpeakers ruolo="studente" speaker={this.handleSpeaker} /></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-3">{_l.lab_nome}</div>
            <div className="col-sm-12 col-md-9"><input className="form-control" onChange={this.onChangeNome} value={this.state.nome} type="text" placeholder={_l.pla_nome} /></div>
          </div>
          <div className="mt-3 mb-3"><button type="submit" className="btn btn-primary btn-100">{_l.btn_send_q}</button></div>
        </form>
      </div>
      <hr />
      </React.Fragment>
    );
  }
}

/* form risposta domanda platea ********************************************* */

class FormAnswerQuestion extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      nome:'',
      risposta:'',
      speaker:0,
    };

  }

  onChangeNome = (e) => {
    this.setState({nome: e.target.value});
  }

  onChangeRisposta = (e) => {
    if (e.target.value.length<=400) this.setState({risposta: e.target.value});
  }

  /*
  handleSpeaker = (v) => {
    this.state.speaker = v;
    this.setState({speaker: v});
  }
  */

  sendAnswer = (e) => {

    e.preventDefault();

    if (this.state.risposta.trim()==='') return;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'answer2Question', id:this.props.id, pid:window.pid, nome:this.state.nome, risposta:this.state.risposta},
      success: function(data) {
        if (data) {
          showMessage(_l.msg_answok);
          this.setState({risposta: '', nome: ''});
          $("#dettaglio-"+this.props.id).slideUp(); //nascondo il form
        } else {
          showMessage(_l.msg_syserr);
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("answer2Question error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('FormAnswerQuestion');

    return (
      <div className="mb-4">
        <form onSubmit={this.sendAnswer}>
          <div className="mb-1"><textarea className="form-control" onChange={this.onChangeRisposta} value={this.state.risposta} placeholder={_l.pla_risposta} rows="5" maxLength="400" required /></div>
          <div className="nota mb-3">{_l.pla_car1}{400-this.state.risposta.length}{_l.pla_car2}</div>
          {/*
          <div className="row">
            <div className="col-sm-12 col-md-3">{_l.lab_destinatario}</div>
            <div className="col-sm-12 col-md-9"><GetSpeakers ruolo="studente" speaker={this.handleSpeaker} /></div>
          </div>
          */}
          <div className="row">
            {/*<div className="col-sm-12 col-md-3">{_l.pla_nome_r}</div>*/}
            <div className="col-sm-12 col-md-12"><input className="form-control" onChange={this.onChangeNome} value={this.state.nome} type="text" placeholder={_l.pla_nome_r} required /></div>
          </div>
          <div className="mt-3 mb-3"><button type="submit" className="btn btn-primary btn-100">{_l.btn_send_r}</button></div>
        </form>
      </div>
    );
  }
}

/* polling domande vista utente ********************************************* */

class GetQuestionsUser extends React.Component {

  render() {

    if(_debug) console.log('GetQuestionsUser');

    let my_questions = _my_questions.map(function(d) {
      let relatore = (d.nome===null) ? _l.lab_tutti : (d.nome+' '+d.cognome)
      let approvata = 'info' // d.stato==='1' ? 'success' : 'info'
      return (
        <div className={"mb-2 alert alert-"+approvata} key={d.id}>
          <div className="row">
            <div className="col-12">{d.domanda}&nbsp;&nbsp;<i>({_l.lab_per} {relatore})</i></div>
          </div>
        </div>
      );
    });

    let div_questions = '';
    if (this.props.domande_platea==='1' || this.props.domande_platea==='3') {

      let questions = _questions.map(function(d) {
        let relatore = (d.nome===null) ? _l.lab_tutti : (d.nome+' '+d.cognome)
        let approvata = 'info' // (d.stato==='1' && d.email===this.props.user.email) ? 'success' : 'info'

        let answers = []
        for (let ii=0; ii<_answers.length; ii++) {
          if (_answers[ii].id_domanda===d.id)
            answers[ii] =
              <React.Fragment key={ii}>
                <hr />
                <div className="ml-3">
                  <div className="risposta-da">Risposta da <span className="font-italic">{_answers[ii].nome}</span></div>
                  {_answers[ii].risposta}
                </div>
              </React.Fragment>
        }

        return (
          <div className={"mb-2 alert alert-"+approvata} key={d.id}>
            <div className="row">
              <div className="col-12">{d.domanda}&nbsp;&nbsp;<i>({_l.lab_per} {relatore})</i>{answers}</div>
            </div>
          </div>
        );
      }, this);

      div_questions =
        <div className="mt-3">
          {questions.length ? <h6 className="etichetta-grigia">{_l.lab_listadom}</h6> : ''}
          {questions}
        </div>
    }

    return (
      <div>
        {my_questions.length ? <h6 className="etichetta-grigia">{_l.lab_listamydom}</h6> : ''}
        {my_questions}
        {div_questions}
      </div>
    );
  }
}

/* polling domande vista tutor ********************************************** */

class GetQuestionsTutor extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      json_data:[],
      new_data:0
    };

  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => this.checkData(), _question_polling);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.speaker !== prevProps.speaker) {
      this.getData();
    }
  }

  checkData = () => {

    if(_debug) console.log('polling domande platea');

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getQuestions', pid:window.pid, relatore:this.props.speaker},
      success: function(data) {
        if (data) {
          data = JSON.parse(data);
          let new_data = data.length-this.state.json_data.length;

          //[1] Elimino eventuali nuovi messaggi così posso aggiornare il DOM per non perdere eventuali click sulla colonna 'visto'.
          //[2] I nuovi messaggi verranno poi caricati su richiesta dell'utente.

          if (this.props.user.ruolo==='tutor' || this.props.config.domande_platea==='2') {
            for (let ii=0; ii<new_data; ii++) data.shift(); //[1]
            this.setState({json_data:data});
            this.setState({new_data:new_data}); //[2]
          } else {
            this.setState({json_data:data});
          }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getQuestions error - ", status, err.toString());
      }
    });
  }

  getData = () => {

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getQuestions', pid:window.pid, relatore:this.props.speaker},
      success: function(data) {
        if (data) {
          this.setState({json_data: JSON.parse(data), new_data:0});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getQuestions error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('GetQuestionsTutor');

    let fontsize_domande = (this.props.user.ruolo==='moderatore' || this.props.user.ruolo==='proiettore') ? 'testo-table-domande' : ''

    let btn_new_data = '';
    if (this.state.new_data===1) btn_new_data = <button className="btn btn-success btn-sm btn-100 mb-2" onClick={this.getData}>{_l.lab_new_data1} {this.state.new_data} {_l.lab_new_data2}</button>
    if (this.state.new_data > 1) btn_new_data = <button className="btn btn-success btn-sm btn-100 mb-2" onClick={this.getData}>{_l.lab_new_data1} {this.state.new_data} {_l.lab_new_data3}</button>

    let html = this.state.json_data.map(function(d) {
      let stato = d.stato==='1' ? true : false;
      if (this.props.user.ruolo!=='tutor' && this.props.config.domande_platea!=='2' && !stato)
        return false;
      else
        return <DoQuestionRow key={d.id} stato={stato} d={d} user={this.props.user} config={this.props.config} />
    }.bind(this));

    let lab_evidenzia_mostra = null;
    if (this.props.user.ruolo==='tutor' || this.props.user.ruolo==='docente' || this.props.user.ruolo==='moderatore') lab_evidenzia_mostra = <th>{_l.lab_attiva}</th>
    if (this.props.user.ruolo==='tutor' && (this.props.config.domande_platea==='0' || this.props.config.domande_platea==='1')) lab_evidenzia_mostra = <th>MOSTRA</th>

    return (
      <div>
        {btn_new_data}
        <table className={"table table-sm table-domande "+fontsize_domande}>
          <thead className="thead-dark">
            <tr>
              <th>{_l.lab_ora}</th>
              <th width="70%">{_l.lab_domande2}</th>
              <th>{_l.lab_relatore}</th>
              {lab_evidenzia_mostra}
            </tr>
          </thead>
          {html}
        </table>
      </div>
    );

  }
}

/* crea riga tabella domande ************************************************ */

class DoQuestionRow extends React.Component {

  constructor(props, context) {

    super(props, context);

    let id_dp = 'DP'+this.props.d.id;
    let preferito = Cookies.get(id_dp) ? parseInt(Cookies.get(id_dp)) : 0;

    this.state = {
      stato: this.props.stato,
      preferito: preferito,
    };

  }

  componentDidUpdate(prevProps) {
    if (this.props.stato !== prevProps.stato) {
      this.setState({stato: this.props.stato});
    }
  }

  handleRisposta = (r) => {
    this.props.d.risposta = r;
  }

  toogleDetail = () => {

    let id = "#dettaglio-"+this.props.d.id;
    if ($(id).css('display') === 'none') {
      $(id).slideDown(); //show
    } else {
      $(id).slideUp(); //hide
    }
  }

  pubQuestion = (e) => {

    let stato = 0;
    if (e.target.checked) stato = 1;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'pubQuestion', pid:window.pid, id:this.props.d.id, stato:stato},
      success: function(data) {
        this.setState({stato: stato});
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("pubQuestion error - ", status, err.toString());
      }
    });
  }

  togglePreferito = () => {
    //docenti/moderatori: preferiti su cookie
    //let preferito = 0;
    //if (e.target.checked) preferito = 1;

    let preferito = this.state.preferito ? 0 : 1;
    let id_dp = 'DP'+this.props.d.id;
    Cookies.set(id_dp, preferito, { expires:1, sameSite:"strict" });
    this.setState({preferito: preferito});
  }

  render() {

    if(_debug) console.log('DoQuestionRow');

    let form_risposta = <FormAnswerQuestion id={this.props.d.id} />

    let preferiti = null; // this.state.preferito ? 'preferiti' : '';
    let ico_pref = this.state.preferito ? ico_pref_on : ico_pref_off;

    let nome = this.props.d.nome ? ' ('+this.props.d.nome+')' : '';
    let nome_r = this.props.d.nome_r ? this.props.d.nome_r+' '+this.props.d.cognome_r : 'Per tutti';

    // risposte alle domande (_answers)
    let answers = []
    if (this.props.config.domande_platea==='1' || this.props.config.domande_platea==='3')
      for (let ii=0; ii<_answers.length; ii++)
        if (_answers[ii].id_domanda===this.props.d.id)
          answers[ii] =
            <React.Fragment key={ii}>
              <hr />
              <div className="ml-3">
                <div className="risposta-da">{_l.lab_risposta_da}<span className="font-italic">{_answers[ii].nome}</span></div>
                {_answers[ii].risposta}
              </div>
            </React.Fragment>

    return (
      <tbody>
        <tr className={preferiti}>
          <td>{this.props.d.data.substring(11, 16)}</td>
          <td>
            {/*<a onClick={this.toogleDetail}>{this.props.d.domanda+nome}</a>*/}
            {this.props.d.domanda+nome}
            {
              (this.props.config.domande_platea==='1' || this.props.config.domande_platea==='3') ? <div className="mt-2 mb-2"><a onClick={this.toogleDetail}>{_arrow_r} {_l.lab_rispondi}</a></div> : ''
            }
            {answers}
          </td>
          <td>{nome_r}</td>
          {
            (this.props.user.ruolo==='tutor' && (this.props.config.domande_platea==='0' || this.props.config.domande_platea==='1'))
            ?
            <td className="text-center---">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id={'customCheck-'+this.props.d.id} onChange={this.pubQuestion} checked={this.state.stato} />
                <label className="custom-control-label" htmlFor={'customCheck-'+this.props.d.id}></label>
              </div>
            </td>
            :
            <td className="text-center---">
              <a onClick={()=>this.togglePreferito()}> {ico_pref} </a>
            </td>

          }
        </tr>
        <tr className="no-display" id={'dettaglio-'+this.props.d.id}>
          <td></td>
          <td colSpan="3">
            <div>{form_risposta}</div>
          </td>
        </tr>
      </tbody>
    );

            {/*
            <td className="text-center">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id={'customCheck-'+this.props.d.id} onChange={this.togglePreferito} checked={this.state.preferito} />
                <label className="custom-control-label" htmlFor={'customCheck-'+this.props.d.id}></label>
              </div>
            </td>
            */}

  }
}

/* tendina relatori ********************************************************* */

class GetSpeakers extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      json_data:false,
      speaker:''
    };

  }

  componentDidMount() {

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSpeakers', pid:window.pid},
      success: function(data) {
        if (data) {
          this.setState({json_data: JSON.parse(data)});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("getSpeakers error - ", status, err.toString());
      }
    });
  }

  onChange = (e) => {
    this.setState({speaker: e.target.value});
    this.props.speaker(e.target.value);
  }

  render() {

    if(_debug) console.log('GetSpeakers');

    if (!this.state.json_data) return null; //nessun relatore

    let html = this.state.json_data.map(d => <option key={d.id} value={d.id}>{d.nome} {d.cognome}</option>);

    let def_opt = '';
    if (this.props.ruolo==='studente') def_opt = <option value="0">{_l.pla_relatore}</option>
    if (this.props.ruolo==='docente' ) def_opt = <option value="0">{_l.pla_relatori}</option>

    return (
      <select className="custom-select mb-2" value={this.state.speaker} onChange={this.onChange}>
        {def_opt}
        {html}
      </select>
    );
  }
}

/* Modal domande studente *************************************************** */

class DomandeStudente extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if(_debug) console.log('DomandeStudente');

    return (
      <div className="modal fade" id="modal-domande-studente" role="dialog" aria-labelledby="modal-domande-studente-title" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-domande-studente-title">{_l.lab_domande1}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 className="mb-3">{_l.lab_compila}</h6>
              <FormQuestion domande_platea={this.props.config.domande_platea} />
              <GetQuestionsUser user={this.props.user} domande_platea={this.props.config.domande_platea} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal domande x tutor **************************************************** */

class DomandeTutor extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleSpeaker = (v) => {
    this.setState({speaker: v});
  }

  render() {

    if(_debug) console.log('DomandeTutor');

    let help = _l.hlp_domande1;
    if ((this.props.config.domande_platea==='1') || (this.props.config.domande_platea==='3')) help += _l.hlp_domande2;
    //help += _l.hlp_domande3;
    /*
    let modal_width = 'modal-xl';
    if (this.props.user.ruolo==='moderatore' || this.props.user.ruolo==='proiettore') modal_width = 'modal-full';
    */
    let modal_width = 'modal-full';

    return (
      <div className="modal fade" id="modal-domande-docente" role="dialog" aria-labelledby="modal-domande-docente-title" aria-hidden="true">
        <div className={"modal-dialog modal-dialog-scrollable "+modal_width} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-domande-docente-title">{_l.lab_listadomdoc}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="mb-3">{help}</div>
              <div className="mb-3">
                {_l.lab_sel_destinatario}
                <GetSpeakers ruolo="docente" speaker={this.handleSpeaker} />
              </div>
              <GetQuestionsTutor speaker={this.state.speaker} user={this.props.user} config={this.props.config} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal dettagli survey studente ******************************************* */

class SurveyDetailStudente extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  LeaveSurveyDetail = () => {

    $('#toast-survey-detail').toast('show');
    $('#modal-survey-detail-studente').modal('hide');

  }

  render() {

    if(_debug) console.log('SurveyDetailStudente');

    let modal_width = 'modal-lg';
    if (this.props.ruolo==='docente') modal_width = 'modal-xl';
    if (this.props.ruolo==='proiettore' || this.props.ruolo==='moderatore') modal_width = 'modal-full';

    let modal_title = <h5 className="modal-title" id="modal-survey-detail-studente-title">{_l.lab_survey_detail}</h5>
    if (this.props.ruolo==='proiettore') modal_title = <h4 className="modal-title" id="modal-survey-detail-studente-title">{_l.lab_survey_detail}</h4>

    return (
      <>
      <div className="modal fade" id="modal-survey-detail-studente" role="dialog" data-backdrop="static" aria-labelledby="modal-survey-detail-studente-title" aria-hidden="true">
        <div className={"modal-dialog modal-xl modal-dialog-scrollable " + modal_width} role="document">
          <div className="modal-content">
            <div className="modal-header">
              {modal_title}
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true" onClick={this.LeaveSurveyDetail}>{_close}</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="u-survey-detail" />
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

/* Modal survey x tutor ***************************************************** */

class SurveyTutor extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if(_debug) console.log('SurveyTutor');

    return (
      <div className="modal fade" id="modal-quiz-docente" role="dialog" aria-labelledby="modal-quiz-docente-title" aria-hidden="true">
        <div className="modal-dialog modal-xxl modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">{_close}</span></button>
              <br />
              <GetSurveys mostra_contatti={this.props.mostra_contatti} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal timer x tutor ****************************************************** */

class TimerTutor extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      t:0,
      tempo:'',
      avviato:false
    };
  }

  onChangeTime = (e) => {
    this.setState({tempo: e.target.value});
  }

  setStandardTime = (t) => {
    //this.setState({tempo: t*60});
    this.setState({tempo: t});
  }

  setTimer = (e) => {

    e.preventDefault();

    if (isNaN(this.state.tempo)) {
      showMessage("Inserisci un valore numerico, grazie.");
      return;
    }

    let tempo = this.state.tempo===-1 ? this.state.tempo : this.state.tempo*60;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setTimerDocente', tempo:tempo, pid:window.pid},
      success: function(data) {
        if (data) {
          /*
          clearTimeout(this.id_timer);
          this.setState({avviato:true});
          this.localTimer(this.state.tempo);
          */
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setTimerDocente error - ", status, err.toString());
      }
    });
  }
  /*
  localTimer = (t) => {
    t--;
    if (t>=0) {
      this.setState({t:t});
      this.id_timer = setTimeout(() => this.localTimer(t), 1*1000);
    } else {
      this.setState({avviato:false});
    }
  }
  */
  render() {

    if(_debug) console.log('TimerTutor');

    let avvia =
      <React.Fragment>
        <button type="submit" className="btn btn-primary btn-sm mr-3">Avvia il timer</button>
        <button type="submit" className="btn btn-dark btn-sm" onClick={()=>{this.setState({tempo:-1})}}>Ferma il timer</button>
      </React.Fragment>

    return (
      <div className="modal fade" id="modal-timer" role="dialog" aria-labelledby="modal-timer-title" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-time-title">Timer per il docente</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body" id="modal-time-text">

              <form className="timer-form" onSubmit={this.setTimer}>
                <div className="form-group">
                  <label htmlFor="InputCodice">Per far partire il timer, inserisci il numero di <strong>minuti</strong> desiderato (o seleziona una durata standard) e fai click su "Avvia il timer".</label>
                  <input type="text" className="form-control" id="InputTime" value={this.state.tempo}  onChange={this.onChangeTime} required />
                </div>
                Durate standard:&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(5)}}>5 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(10)}}>10 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(15)}}>15 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(20)}}>20 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(25)}}>25 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(30)}}>30 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(35)}}>35 min.</a>&nbsp;|&nbsp;
                <a className="" onClick={()=>{this.setStandardTime(40)}}>40 min.</a>&nbsp;|&nbsp;
                <br /><br />
                {avvia}
              </form>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal raccolta link ****************************************************** */

class Link extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  setRisorsa = (id,stato) => {

    stato = stato ? 0 : 1;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setRisorsa', stato:stato, tipo:'link', id:id, pid:window.pid},
      success: function(data) {
        if (data) {
          this.props.refresh(true);
          showMessage("Risorsa aggiornata!");
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setRisorsa error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('Link');

    let ruolo = this.props.user ? this.props.user.ruolo : '';
    let d = this.props.links ? this.props.links : false;

    let html = [];

    let help = '';
    if (ruolo==='tutor') help = <div className="mb-2">{_l.hlp_link}</div>

    for (let ii=0; ii<d.length; ii++) {

      let stato = d[ii].stato=='1' ? true : false;

      if (ruolo==='tutor') {

        html[ii] =
          <React.Fragment key={ii}>
            <div className="row mb-2">
              <div className="col-sm-12 col-md-10">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id={"link-"+ii} onChange={()=>this.setRisorsa(d[ii].id,stato)} checked={stato} />
                  <label className="custom-control-label" htmlFor={"link-"+ii}>{d[ii].descrizione}</label>
                </div>
              </div>
              <div className="col-sm-12 col-md-2"><a className="btn btn-sm btn-100 btn-light" href={d[ii].url} target="_blank" rel="noopener noreferrer">{_link}</a></div>
            </div>
            <hr />
          </React.Fragment>

      } else {

        if (stato)
          html[ii] =
            <React.Fragment key={ii}>
              <div className="row mb-2">
                <div className="col-sm-11 desc_file_link">{d[ii].descrizione}</div>
                <div className="col-sm-1"><a className="btn btn-sm btn-100 btn-light" href={d[ii].url} target="_blank" rel="noopener noreferrer">{_link}</a></div>
              </div>
              <hr />
            </React.Fragment>
      }

    }

    let dati = <div>{_l.msg_nolink}</div>
    if (html.length) dati = html;

    return (
      <div className="modal fade" id="modal-link" role="dialog" aria-labelledby="modal-link-title" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-link-title">{_l.lab_link}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body" id="modal-link-text">
              {help}
              {dati}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal raccolta file ****************************************************** */

class File extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  setRisorsa = (id,stato) => {

    stato = stato ? 0 : 1;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setRisorsa', stato:stato, tipo:'file', id:id, pid:window.pid},
      success: function(data) {
        if (data) {
          this.props.refresh(true);
          showMessage("Risorsa aggiornata!");
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setRisorsa error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('File');

    let ruolo = this.props.user ? this.props.user.ruolo : '';
    let d = this.props.files ? this.props.files : false;

    let html = [];

    let help = '';
    if (ruolo==='tutor') help = <div className="mb-2">{_l.hlp_file}</div>

    for (let ii=0; ii<d.length; ii++) {

      let stato = d[ii].stato=='1' ? true : false;

      if (ruolo==='tutor') {

        html[ii] =
          <React.Fragment key={ii}>
            <div className="row mb-2">
              <div className="col-sm-12 col-md-10">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id={"file-"+ii} onChange={()=>this.setRisorsa(d[ii].id,stato)} checked={stato} />
                  <label className="custom-control-label" htmlFor={"file-"+ii}>{d[ii].descrizione}</label>
                </div>
              </div>
              <div className="col-sm-12 col-md-2"><a className="btn btn-sm btn-100 btn-light" href={d[ii].url} target="_blank" rel="noopener noreferrer">{_down}</a></div>
            </div>
            <hr />
          </React.Fragment>

      } else {

        if (stato)
          html[ii] =
            <React.Fragment key={ii}>
              <div className="row mb-2">
                <div className="col-sm-11 desc_file_link">{d[ii].descrizione}</div>
                <div className="col-sm-1 text-center"><a className="btn btn-sm btn-100 btn-light" href={d[ii].url} target="_blank" rel="noopener noreferrer">{_down}</a></div>
              </div>
              <hr />
            </React.Fragment>
      }

    }

    let dati = <div>{_l.msg_nofile}</div>
    if (html.length) dati = html;

    return (
      <div className="modal fade" id="modal-file" role="dialog" aria-labelledby="modal-file-title" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-file-title">{_l.lab_file}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body" id="modal-file-text">
              {help}
              {dati}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal programma evento *************************************************** */

class Programma extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      commento:'',
      domanda:null,
      warning:false,
      r_programma:null,
      id_risposta:null,
    };
  }

  setProgramma = (id) => {

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setProgram', id:id, pid:window.pid},
      success: function(data) {
        if (data) {
          this.props.refresh(true);
          showMessage("Programma aggiornato!");
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setProgram error - ", status, err.toString());
      }
    });

  }

  showModalValutazione = (r_programma) => {

    if (r_programma===this.state.r_programma) {
      $('#modal-survey-programma').modal('show');
      return;
    }

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'getSurvey', pid:window.pid, sid:r_programma.id_domanda},
      success: function(data) {
        if (data) {
          $('#modal-survey-programma').modal('show');
          if ($('#commento').val()) $('#commento').val('');
          this.setState({id_risposta:null, commento:'', warning:null, r_programma:r_programma, domanda:JSON.parse(data)});
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setProgram error - ", status, err.toString());
      }
    });
  }


  onClicValutazione = (id) => {
    this.setState({id_risposta:id, warning:false});
  }

  setValutazione = () => {

    if (!this.state.id_risposta) {
      this.setState({warning:true});
      return;
    }

    let commento = $('#commento').val();

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setValutazione', pid:window.pid, id_domanda:this.state.r_programma.id_domanda, id_attivita:this.state.r_programma.id, id_risposta:this.state.id_risposta, commento:commento},
      success: function(data) {
        this.props.refresh(true);
        if (commento) $('#commento').val('');
        //$('#btn-val-'+this.state.r_programma.id).hide(); //nascondo subito il bottone
        this.setState({domanda:null, r_programma:null, id_risposta:null, commento:''});
        showMessage("Valutazione inviata!");
        $('#modal-survey-programma').modal('hide');
      }.bind(this),
      error: function(xhr, status, err) {
        showMessage("Errore durante l'invio!");
        console.error("setValutazione error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('Programma');

    let label = '';
    let html_tabs = [];
    let html_content = [];

    let ruolo = this.props.user ? this.props.user.ruolo : '';
    let p = this.props.programma ? this.props.programma : false;

    let valuta = ruolo==='studente';

    //ciclo per ogni riga del programma, ad ogni cambio label creo un tab e riciclo per creare il programma di quella label
    //
    for (let ii=0; ii<p.length; ii++) {

      if (p[ii].label!==label) {

        let active = '';
        label = p[ii].label;

        let html = [];
        for (let jj=ii; jj<p.length; jj++) {

          if (p[jj].label!==label) break;

          let hilite = '';
          let incorso = '';
          if (p[jj].stato==='1') {
            active = 'show active';
            hilite = 'programma-attivo';
            incorso = _l.msg_incorso;
            valuta = false;
          }
          if (p[jj].id_domanda && !p[jj].valutato && valuta) {
            incorso = <button id={'btn-val-'+p[jj].id} className="btn btn-sm btn-primary" onClick={()=>this.showModalValutazione(p[jj])}><span className="pulse">{_l.btn_valuta}</span></button>
          }

          let orario = p[jj].orario;
          if (ruolo==="tutor") orario = <a className="orario decoro" onClick={()=>this.setProgramma(p[jj].id)}>{p[jj].orario}</a>

          let attivita = p[jj].attivita;
          if (p[jj].attore) attivita = p[jj].attivita+'<div><i class="text-small">'+p[jj].attore+'</i></div>';

          html[jj] =
            <div key={jj}>
            <div className="row mb-2">
              <div className={"col-sm-12 col-md-12 col-lg-1 orario "+hilite}>{orario}</div>
              <div className={"col-sm-12 col-md-12 col-lg-11 attivita "+hilite} dangerouslySetInnerHTML={createMarkup(attivita)} />
            </div>
            <div className="row mb-3">
              <div className="col-sm-1 col-md-1 col-lg-1"></div>
              <div className="col-sm-11 col-md-11 col-lg-11">{incorso}</div>
            </div>
            </div>

        }

        html_tabs[ii] =
          <a key={ii} className={"nav-link nav-programma "+active} id={"nav-tab-"+ii} data-toggle="tab" href={"#nav-"+ii} role="tab">{label}</a>

        html_content[ii] =
          <div key={ii} className={"tab-pane fade "+active} id={"nav-"+ii} role="tabpanel">{html}</div>
      }

    }

    let help = '';
    if (ruolo==='tutor') help = <div className="mb-2">{_l.hlp_programma}</div>

    let div_domanda = null;
    if (ruolo==='studente' && this.state.domanda) {

      let d = this.state.domanda;
      let testo = d[0].testo.replace("%orario%", this.state.r_programma.orario);
      testo = testo.replace("%attivita%", this.state.r_programma.attivita);
      testo = testo.replace("%attore%", this.state.r_programma.attore);
      let domanda = <div className="mb-3 testo-domanda" dangerouslySetInnerHTML={createMarkup(testo)} />

      let commento = d[0].commento==='1' ? <textarea className="form-control mb-4" rows="3" maxLength="400" id="commento" placeholder={_l.pla_valutazione} /> : null;

      let risposte = [];
      for (let ii=0; ii<d.length; ii++) {
        let html_risposta =
          <div className="testo-risposta p-2" dangerouslySetInnerHTML={createMarkup(d[ii].risposta)} />
          /*
          <table>
            <tbody>
              <tr>
                <td className="txt-risposta"><div className="testo-risposta" dangerouslySetInnerHTML={createMarkup(d[ii].risposta)} /></td>
              </tr>
            </tbody>
          </table>
        */
        let risposta_on = (this.state.id_risposta===d[ii].id_risposta) ? ' sel-risposte' : ''; //scelta evidenziata
        //let risposta = <button type="button" className={"btn btn-risposte btn-100 mb-1 div-risposta-"+_lettere[ii]+risposta_on} onClick={()=>this.onClicValutazione(d[ii].id_risposta)}>{html_risposta}</button>
        let risposta = <button type="button" className={"btn btn-risposte btn-100 mb-1 p-3 "+risposta_on} onClick={()=>this.onClicValutazione(d[ii].id_risposta)}>{html_risposta}</button>

        risposte[ii] =
          <React.Fragment key={'rr-'+ii}>
            <div className="row mb-2" key={'r-'+ii}>
              <div className="col-sm-12 col-md-12">{risposta}</div>
            </div>
          </React.Fragment>
      }

      let warning = null;
      if (this.state.warning) warning = <span className="ml-3">{_l.msg_valuta_2}</span>

      div_domanda =
        <React.Fragment>
          <div className="mb-2">{_l.hlp_valuta}</div>
          {domanda}
          {risposte}
          {commento}
          <button type="button" className="btn btn-primary" onClick={()=>this.setValutazione()}>{_l.btn_conf}</button>
          {warning}
        </React.Fragment>

    }

    return (
      <>
        <div className="modal fade" id="modal-programma" role="dialog" aria-labelledby="modal-programma-title" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modal-programma-title">{_l.btn_programma}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">{_close}</span>
                </button>
              </div>
              <div className="modal-body">
                {help}
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">{html_tabs}</div>
                </nav>
                <div className="tab-content mt-3" id="nav-tabContent">{html_content}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-survey-programma" role="dialog" data-backdrop="static" aria-labelledby="modal-survey-programma-title" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div><h5 className="modal-title" id="modal-link-title">{_l.lab_valuta}</h5></div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">{_close}</span></button>
              </div>
              <div className="modal-body mb-4">
                {div_domanda}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

/* Modal patrocini evento *************************************************** */

class Patrocini extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if (!this.props.course) return(false);

    if(_debug) console.log('Patrocini');

    let html = [];
    let d = this.props.course.patrocini;
    let livello = false;
    for (let ii=0; ii<d.length; ii++) {

      let html_livello = '';
      if (d[ii].livello!==livello) {
        livello = d[ii].livello;
        html_livello =
          <div className="row mb-1 mt-4">
            <div className="col-12">
              <img className="img-fluid" src={"img/"+livello+'-'+this.props.lang+".svg"} alt="" />
            </div>
          </div>
      }

      let logo = d[ii].logo ? <img className="img-sponsor m-4" src={"data:image/jpg;base64,"+d[ii].logo} alt="" /> : d[ii].nome
      let spns = d[ii].url ? <a href={d[ii].url} target="_blank" rel="noopener noreferrer">{logo}</a> : logo;

      html[ii] =
        <React.Fragment key={ii}>
          {html_livello}
          {spns}
        </React.Fragment>
    }

    return (
      <div className="modal fade" id="modal-patrocini" role="dialog" aria-labelledby="modal-patrocini-title" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-patrocini-title">{_l.btn_patrocini}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body sponsor">
              {html}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal sponsor evento ***************************************************** */

class Sponsor extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if (!this.props.course) return(false);

    if(_debug) console.log('Sponsor');

    let html = [];
    let d = this.props.course.sponsor;
    let livello = false;
    for (let ii=0; ii<d.length; ii++) {

      let html_livello = '';
      if (d[ii].livello!==livello) {
        livello = d[ii].livello;
        html_livello =
          <div className="row mb-1 mt-4">
            <div className="col-12">
              <img className="img-fluid" src={"img/"+livello+".svg"} alt="" />
            </div>
          </div>
      }

      let logo = d[ii].logo ? <img className="img-sponsor m-4" src={"data:image/jpg;base64,"+d[ii].logo} alt="" /> : d[ii].nome;
      let spns = d[ii].url ? <a href={d[ii].url} target="_blank" rel="noopener noreferrer">{logo}</a> : logo;

      html[ii] =
        <React.Fragment key={ii}>
          {html_livello}
          {spns}
        </React.Fragment>
    }

    return (
      <div className="modal fade" id="modal-sponsor" role="dialog" aria-labelledby="modal-sponsor-title" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-sponsor-title">{_l.btn_sponsor}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body sponsor">
              {html}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal faculty evento ***************************************************** */

class Faculty extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if (!this.props.course) return(false);

    if(_debug) console.log('Faculty');

    return (
      <div className="modal fade" id="modal-faculty" role="dialog" aria-labelledby="modal-faculty-title" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-faculty-title">{_l.btn_faculty}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {this.props.course.coordinatori.length ?<a className="nav-link nav-programma" id="nav-tab-f2" data-toggle="tab" href="#nav-f2" role="tab">{_l.lab_coordinatori}</a> : ''}
                  {this.props.course.responsabili.length ?<a className="nav-link nav-programma" id="nav-tab-f1" data-toggle="tab" href="#nav-f1" role="tab">{_l.lab_responsabili}</a> : ''}
                  {this.props.course.comitato.length ?    <a className="nav-link nav-programma active" id="nav-tab-f3" data-toggle="tab" href="#nav-f3" role="tab">{_l.lab_comitato}</a> : ''}
                  {this.props.course.tutor.length ?       <a className="nav-link nav-programma" id="nav-tab-f4" data-toggle="tab" href="#nav-f4" role="tab">{_l.lab_tutor}</a> : ''}
                  {this.props.course.docenti.length ?     <a className="nav-link nav-programma" id="nav-tab-f5" data-toggle="tab" href="#nav-f5" role="tab">{_l.lab_docenti}</a> : ''}
                  {this.props.course.moderatori.length ?  <a className="nav-link nav-programma" id="nav-tab-f6" data-toggle="tab" href="#nav-f6" role="tab">{_l.lab_moderatori}</a> : ''}
                  {this.props.course.relatori.length ?    <a className="nav-link nav-programma" id="nav-tab-f7" data-toggle="tab" href="#nav-f7" role="tab">{_l.lab_relatori}</a> : ''}
                  {this.props.course.faculty.length ?     <a className="nav-link nav-programma" id="nav-tab-f8" data-toggle="tab" href="#nav-f8" role="tab">{_l.lab_faculty}</a> : ''}
                </div>
              </nav>
              <div className="tab-content mt-3" id="nav-tabContent">
                {this.props.course.coordinatori.length ?<div className="tab-pane fade" id="nav-f2" role="tabpanel">{htmlFaculty(this.props.course.coordinatori)}</div> : ''}
                {this.props.course.responsabili.length ?<div className="tab-pane fade" id="nav-f1" role="tabpanel">{htmlFaculty(this.props.course.responsabili)}</div> : ''}
                {this.props.course.comitato.length ?    <div className="tab-pane fade show active" id="nav-f3" role="tabpanel">{htmlFaculty(this.props.course.comitato)}</div> : ''}
                {this.props.course.tutor.length ?       <div className="tab-pane fade" id="nav-f4" role="tabpanel">{htmlFaculty(this.props.course.tutor)}</div> : ''}
                {this.props.course.docenti.length ?     <div className="tab-pane fade" id="nav-f5" role="tabpanel">{htmlFaculty(this.props.course.docenti)}</div> : ''}
                {this.props.course.moderatori.length ?  <div className="tab-pane fade" id="nav-f6" role="tabpanel">{htmlFaculty(this.props.course.moderatori)}</div> : ''}
                {this.props.course.relatori.length ?    <div className="tab-pane fade" id="nav-f7" role="tabpanel">{htmlFaculty(this.props.course.relatori)}</div> : ''}
                {this.props.course.faculty.length ?     <div className="tab-pane fade" id="nav-f8" role="tabpanel">{htmlFaculty(this.props.course.faculty)}</div> : ''}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal dati ECM *********************************************************** */

class DatiEcm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {

    if (!this.props.course) return(false);

    if(_debug) console.log('DatiEcm');

    let url_attivita =  "https://fad.accmed.org/course/view.php?id="+this.props.course.id;
    let a_attivita = '<a href="'+url_attivita+'" target="_blank" rel="noopener noreferrer">'+url_attivita+'</a>';

    let data = new Date();
    let anno = data.getFullYear();

    return (
      <div className="modal fade" id="modal-ecm" role="dialog" aria-labelledby="modal-ecm-title" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-ecm-title">{_l.lab_ecm}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="colore-primario">{this.props.course.titolo}</h5>
              <p>
                {_l.lab_crediti_ecm} {this.props.course.crediti}<br />
                {_l.lab_codice_ecm} {this.props.course.numeroprovider+'-'+this.props.course.numeroriferimento}<br />
                {_l.lab_pagina_attivita} <a href={url_attivita} target="_blank" rel="noopener noreferrer">{url_attivita}</a>
              </p>
              <div dangerouslySetInnerHTML={createMarkup(_l.hlp_ecm1+a_attivita+_l.hlp_ecm2)} />
            </div>
            <div className="mt-3 p-3 text-small">
              Accademia Nazionale di Medicina © {anno}. {_l.lab_diritti} <br />
              <a href="https://www.accmed.org" target="_blank" rel="noopener noreferrer">www.accmed.org</a> | <a href="#" data-toggle="modal" data-target="#modal-privacy">{_l.lab_privacy}</a> <br /><br />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal contatti *********************************************************** */

class Contatti extends React.Component {

  componentDidMount() {
    $('#modal-contatti').modal('show');
  }

  sendEmail = (e) => {

    e.preventDefault()
    e.stopPropagation()

    let data = $("#form-contatti").serialize();

    $('#result-contatti').html('<div class="spinner-border text-danger" role="status"><span class="sr-only">Invio in corso...</span></div>');

    $.ajax({
      url: "php/contatti.php",
      type: "POST",
      cache: false,
      data: data,
      success: function(res) {
        let result = JSON.parse(res);
        if (result.stato==='ok')
          $("#result-contatti").html(_l.msg_ok_contact);
        else
          $("#result-contatti").html(_l.msg_trouble_contact);
      },
      error: function(xhr, status, err) {
        $("#result-contatti").html("Ops, qualcosa è andato storto...");
      }
    });

  }

  render() {

    if(_debug) console.log('Contatti');

    let email = '';
    if (this.props.user.email)
      if (this.props.user.email.includes("@")) email = this.props.user.email;

    let nome = this.props.user.nome ? this.props.user.nome+' '+this.props.user.cognome : '';

    return (
      <div className="modal fade" id="modal-contatti" role="dialog" aria-labelledby="modal-contatti-title" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-contatti-title">{_l.btn_contatti}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">{_l.hlp_contatti}</div>
              <form id="form-contatti" onSubmit={this.sendEmail}>
                <input id="corso" name="corso" type="hidden" defaultValue={this.props.course} />
                <div className="row">
                  <div className="col-12">
                    <input id="email" name="email" className="form-control" type="email" required="required" defaultValue={email} placeholder={_l.lab_email} /><br />
                  </div>
                  <div className="col-12">
                    <input id="nome" name="nome" className="form-control" type="text" required="required" defaultValue={nome} placeholder={_l.lab_nome} /><br />
                  </div>
                  <div className="col-12">
                    <textarea id="messaggio" name="messaggio" className="form-control" rows="5" required="required" placeholder={_l.pla_messaggio} ></textarea><br />
                  </div>
                  <div className="col-12 recaptcha">{_l.lab_umano}</div>
                  <div className="col-12 recaptcha"><ReCAPTCHA sitekey={this.props.config.recaptcha_publickey} /></div>
                  <div className="col-12 recaptcha"><button className="btn btn-primary btn-sm btn-email" type="submit">{_l.btn_send_c}</button></div>
                  <div className="col-12" id="result-contatti"></div>
                </div>
              </form>
            </div>
            <div className="p-3 text-small">
              <strong>Credits</strong><br />
              {_version}<br />
              Questa applicazione è stata realizzata - a Genova - da <a href="https://forumservice.net" target="_blank" rel="noopener noreferrer">Forum Service</a>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* Modal dati partecipanti (regia) ****************************************** */

class DatiUtenti extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  setUpdate = (cosa,tipo) => {

    let c = window.confirm("Attenzione, stai per forzare il refresh di tutti gli utenti connessi! Confermi?");
    if (!c) return;

    $.ajax({
      url: "php/services.php",
      type: "POST",
      cache: false,
      data: {action:'setUpdate', cosa:cosa, tipo:tipo, pid:window.pid},
      success: function(data) {
        if (data) {
          showMessage("Aggiornamento client effettuato!");
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error("setUpdate error - ", status, err.toString());
      }
    });
  }

  render() {

    if(_debug) console.log('DatiUtenti');

    let html = [];
    let d = this.props.utenti;
    let totale = 0;
    let attivi_de = 0;
    let attivi_res = 0;
    for (let ii=0; ii<d.length; ii++) {

      totale++;
      if (d[ii].streaming==='1' && d[ii].stato==='1') attivi_de++;
      if (d[ii].streaming==='0' && d[ii].stato==='1') attivi_res++;

      html[ii] =
        <React.Fragment key={d[ii].id}>
          <tr>
            <td>{d[ii].cognome}</td>
            <td>{d[ii].nome}</td>
            <td>{d[ii].ruolo}</td>
            <td>{d[ii].streaming==='1' ? 'SI' : 'NO'}</td>
            <td>{d[ii].stato==='1' ? 'SI' : 'NO'}</td>
          </tr>
        </React.Fragment>
    }

    return (
      <div className="modal fade" id="modal-utenti" role="dialog" aria-labelledby="modal-utenti-title" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-utenti-title">{_l.btn_utenti}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">{_close}</span>
              </button>
            </div>
            <div className="modal-body">
            <button type="button" className="btn btn-primary btn-sm mb-3" onClick={()=>{this.setUpdate('all','ricarica')}}>forza il refresh di tutti gli utenti connessi</button>
              <h6>Utenti DE attivi: {attivi_de}</h6>
              <h6>Utenti RES attivi: {attivi_res}</h6>
              <h6>Utenti attivi totali: {attivi_de+attivi_res}</h6>
              <h6>Utenti totali: {totale}</h6>
              <table className="table table-striped table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th>COGNOME</th>
                    <th>NOME</th>
                    <th>RUOLO</th>
                    <th>BBB</th>
                    <th>ATTIVO</th>
                  </tr>
                </thead>
                <tbody>
                  {html}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">{_l.btn_close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/* slides ******************************************************************* */

class GetSlides extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      num_slide:0,
    };

  }

  componentDidMount() {
    document.getElementById("slide").addEventListener('touchstart', this.handleTouchStart, false);  //evento di inizio tocco
    document.getElementById("slide").addEventListener('touchmove', this.handleTouchMove, false);    //evento di movimento durante il tocco
  }

  componentDidUpdate(prevProps) {
    if (this.props.slides !== prevProps.slides) this.setState({num_slide:0});
  }

  onPrev = () => {
    let ns = this.state.num_slide;
    if (ns>0) {
      ns--;
      this.setState({num_slide:ns});
    }
  }

  onNext = () => {
    let ns = this.state.num_slide;
    if (ns<this.props.slides.length-1) {
      ns++;
      this.setState({num_slide:ns});
    }
  }

  handleTouchStart = (evt) => {
    _xDown = evt.touches[0].clientX;
    _yDown = evt.touches[0].clientY;
  }

  handleTouchMove = (evt) => {
    if ( ! _xDown || ! _yDown ) return; //nessun movimento
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;
    var xDiff = _xDown - xUp;
    var yDiff = _yDown - yUp;
    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      if ( xDiff > 0 ) {
          this.onNext() //swipe sx
      } else {
          this.onPrev() //swipe dx
      }
    } else {
      if ( yDiff > 0 ) {
          //swipe up
      } else {
          //swipe down
      }
    }
    _xDown = null;
    _yDown = null;
  }

  render() {

    if(_debug) console.log('GetSlides');

    return (
      <React.Fragment>
        <div className="mb-3">{this.props.attivita}</div>
        <div className="text-center">
          <img id="slide" className="img-fluid" src={this.props.slides[this.state.num_slide]} />
        </div>
        <div className="row nav-slide text-center">
          <div className="col-5"><button className="btn btn-lg btn-slide text-left" onClick={this.onPrev}>{_left}</button></div>
          <div className="col-2"><button className="btn btn-lg btn-slide text-center slide-counter">{this.state.num_slide+1}/{this.props.slides.length}</button></div>
          <div className="col-5"><button className="btn btn-lg btn-slide text-right" onClick={this.onNext}>{_right}</button></div>
        </div>
      </React.Fragment>
    );
  }
}

/* Modal statiche *********************************************************** */

class Modals extends React.Component {

  render() {

    if(_debug) console.log('Modals');

    let hlp_istruzioni1 = _l.hlp_istruzioni1.replace("$ente$", this.props.config.ente);

    let istruzioni = this.props.config.streaming ? hlp_istruzioni1 + _l.hlp_istruzioni2 : hlp_istruzioni1;

    return (
      <React.Fragment>

        <div className="modal fade" id="modal-survey-detail-votanti" role="dialog" data-backdrop="static" aria-labelledby="modal-survey-detail-votanti-title" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-scrollable modal-full-fixed" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modal-survey-detail-votanti-title">&nbsp;</h5>
                <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal">{_l.btn_close}</button>
              </div>
              <div className="modal-body">
                <div id="u-survey-detail-votanti" />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-zoom" tabIndex="-1" role="dialog" aria-labelledby="modal-zoomLabel" aria-hidden="true">
          <div className="modal-dialog modal-xxl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div id="zoom" className="text-center" onClick={()=>{$('#modal-zoom').modal('hide')}}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-istruzioni" tabIndex="-1" role="dialog" aria-labelledby="modal-istruzioniLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{_l.btn_istruzioni}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">{_close}</span>
                </button>
              </div>
              <div className="modal-body" dangerouslySetInnerHTML={createMarkup(istruzioni)} />
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal">{_l.btn_close}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-privacy" tabIndex="-1" role="dialog" aria-labelledby="modal-privacyLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="embed-responsive embed-responsive-1by1 scroll-wrapper">
                  <iframe title="privacy" className="embed-responsive-item" src="https://www.accmed.org/privacypolicy.html"></iframe>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal">{_l.btn_close}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-alert" tabIndex="-1" role="dialog" aria-labelledby="modal-alertLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body text-center" id="the-alert"></div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal">{_l.btn_close}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="toast-container" aria-live="polite" aria-atomic="true">
          <div id="toast-message" className="toast hide my-toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
            <div className="toast-header">
              <strong className="mr-auto" id="the-message">&nbsp;</strong>
            </div>
          </div>
        </div>

        <div className="toast-container" aria-live="polite" aria-atomic="true">
          <div id="toast-timer" className="toast hide toast-timer" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
            <div className="m-2">
              <span id="the-time"></span>
            </div>
          </div>
        </div>

        <div className="toast-container" aria-live="polite" aria-atomic="true">
          <div id="toast-survey-detail" className="toast hide my-toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
            <div className="m-2">
              <a onClick={()=>{toastSurveyDetail()}}><strong>{_l.msg_open_survey_detail}</strong></a>
            </div>
          </div>
        </div>

        {/*
        <div className="toast-container" aria-live="polite" aria-atomic="true">
          <div id="toast-message-valuta" className="toast hide my-toast-2" role="alert" aria-live="assertive" aria-atomic="true" data-delay="10000">
            <div className="toast-header">
              <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#modal-programma" data-dismiss="toast">{_l.msg_valuta}</button>
            </div>
          </div>
        </div>
        */}

      </React.Fragment>
    );
  }
}

function htmlSchedaHome(d) {

  let attori = d.faculty;
  let lab_attori = _l.lab_faculty;

  if (d.coordinatori.length) {
    attori = d.coordinatori;
    lab_attori = _l.lab_coordinatori;
  }
  if (d.responsabili.length) {
    attori = d.responsabili;
    lab_attori = _l.lab_responsabili;
  }
  if (d.comitato.length) {
    attori = d.comitato;
    lab_attori = _l.lab_comitato;
  }

  return (
    <React.Fragment>
      <h5 className="colore-primario">{d.titolo}</h5>
      <div className="mb-2" dangerouslySetInnerHTML={createMarkup(d.dove)} />
      <div dangerouslySetInnerHTML={createMarkup(d.data_live)} />
      <div className="mt-4 mb-2"><strong>{lab_attori}</strong></div>
      <div>{htmlFaculty(attori)}</div>
    </React.Fragment>
  );
}

function htmlFaculty(d) {

  let html = [];
  for (let ii=0; ii<d.length; ii++) {
    html[ii] =
      <div key={ii} className="row faculty">
        {d[ii].foto ? <div className="col-3"><img alt="faculty" className="img-fluid foto" src={"data:image/jpg;base64,"+d[ii].foto} /></div> : ''}
        <div className="col-9 affiliazione">
          <strong>{d[ii].titolo+' '+d[ii].nome+' '+d[ii].cognome}</strong><br />
          <div dangerouslySetInnerHTML={createMarkup(d[ii].affiliazione)} />
          {d[ii].link ? <a href={d[ii].link} target="_blank" rel="noopener noreferrer">{d[ii].link}</a> : ''}
        </div>
      </div>
  }

  return (html);
}

function toastSurveyDetail() {
  $('#toast-survey-detail').toast('hide');
  $('#modal-survey-detail-studente').modal('show');
}

function showTimerDocente(t) {

  $("#the-time").html("Timer "+t);
  if (t)
    $('#toast-timer').toast('show');
  else
    $('#toast-timer').toast('hide');

}

function toHHMMSS(t) {

  var sec_num = parseInt(t, 10);
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}

  var timing = hours==0 ? minutes+':'+seconds : hours+':'+minutes+':'+seconds;
  return timing;
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
  return age;
}

function zoomIn(src) {

  if (_mobile) return;
  document.getElementById('zoom').innerHTML = '<img class="img-fluid zoom-out" alt="zoom" src="data:image/jpg;base64,'+src+'" width="1500px" />';
  $('#modal-zoom').modal('show');
}

function fileExist(f) {

  let http = $.ajax({ type:"GET", url: f, async: false });
  if (http.status===200)
    return true;
  else
    return false;
}

export default App;
